/* eslint-disable */
import * as types from "./graphql.js";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "\n  query speakingClassServiceAgGridQuery($params: AgGridParamsInput!) {\n    speakingClassServiceAgGridQuery(params: $params) {\n      hasNext\n      data\n    }\n  }\n":
    types.SpeakingClassServiceAgGridQueryDocument,
  "\n  fragment ClassRoomListItem on ClassRoomListItem {\n    id\n    startedAtUtc\n    endedAtUtc\n    type\n    speakingLevel\n    participantsCount\n    capacity\n    topic {\n      id\n      name\n    }\n    teacher {\n      id\n      givenName\n      familyName\n      phone {\n        countryCode\n        number\n      }\n      email\n    }\n    completedFeedbacksCount\n  }\n":
    types.ClassRoomListItemFragmentDoc,
  "\n  fragment ClassRoomWithParticipants on ClassRoom {\n    id\n    speakingLevel\n    startedAtUtc\n    endedAtUtc\n    topic {\n      name\n    }\n    participants {\n      id\n      givenName\n      familyName\n    }\n    capacity\n  }\n":
    types.ClassRoomWithParticipantsFragmentDoc,
  "\n  fragment ClassRoomWithoutParticipant on ClassRoomWithoutParticipantDto {\n    id\n    startedAtUtc\n    endedAtUtc\n    speakingLevel\n    topic {\n      name\n    }\n    participantsCount\n    classRoomTimezone\n    type\n  }\n":
    types.ClassRoomWithoutParticipantFragmentDoc,
  "\n  fragment CompleteClassRoom on CompleteClassRoom {\n    id\n    speakingLevel\n    startedAtUtc\n    endedAtUtc\n    topic {\n      name\n    }\n    participants {\n      id\n      givenName\n      familyName\n      email\n    }\n    capacity\n    teacher {\n      email\n      id\n      familyName\n      givenName\n    }\n    conferenceUri\n    feedbackUrl\n    feedbackReportUrl\n  }\n":
    types.CompleteClassRoomFragmentDoc,
  "\n  query GetMinUsedTopicInLevelAmongParticipants(\n    $participantIds: [String!]!\n    $speakingLevel: SpeakingClassLevel!\n  ) {\n    getMinUsedTopicsInLevelAmongParticipants(\n      participantIds: $participantIds\n      speakingLevel: $speakingLevel\n    ) {\n      ...TopicItem\n    }\n  }\n":
    types.GetMinUsedTopicInLevelAmongParticipantsDocument,
  "\n  mutation createClassRoom(\n    $capacity: Int!\n    $classRoomType: ClassRoomType!\n    $participantIds: [String!]!\n    $speakingLevel: SpeakingClassLevel!\n    $startUtc: String!\n    $teacherId: String!\n    $topicId: String!\n  ) {\n    createClassRoom(\n      capacity: $capacity\n      classRoomType: $classRoomType\n      participantIds: $participantIds\n      speakingLevel: $speakingLevel\n      startUtc: $startUtc\n      teacherId: $teacherId\n      topicId: $topicId\n    ) {\n      id\n    }\n  }\n":
    types.CreateClassRoomDocument,
  "\n  mutation addParticipantsToClassByEmail(\n    $classRoomId: String!\n    $emails: [String!]!\n  ) {\n    addParticipantsToClassByEmail(\n      classRoomId: $classRoomId\n      userEmails: $emails\n    ) {\n      ...ClassRoomWithParticipants\n    }\n  }\n":
    types.AddParticipantsToClassByEmailDocument,
  "\n  query adminGetClassRoomById($id: String!) {\n    adminGetClassRoomById(classRoomId: $id) {\n      ...CompleteClassRoom\n    }\n  }\n":
    types.AdminGetClassRoomByIdDocument,
  "\n  query listClassRooms(\n    $limit: Int!\n    $nextToken: String\n    $prevToken: String\n    $dateRange: ListClassRoomDateInput\n  ) {\n    listClassRooms(\n      limit: $limit\n      nextToken: $nextToken\n      prevToken: $prevToken\n      dateRange: $dateRange\n    ) {\n      classRooms {\n        ...ClassRoomListItem\n      }\n      hasNextPage\n      hasPrevPage\n      nextPageToken\n      prevPageToken\n    }\n  }\n":
    types.ListClassRoomsDocument,
  "\n  mutation deleteClassRoom($classRoomId: String!) {\n    deleteClassRoom(classRoomId: $classRoomId)\n  }\n":
    types.DeleteClassRoomDocument,
  "\n  mutation removeParticipantFromClassRoom(\n    $classRoomId: String!\n    $userId: String!\n  ) {\n    removeParticipantFromClassRoom(classRoomId: $classRoomId, userId: $userId) {\n      classRoomDeleted\n      classRoom {\n        ...ClassRoomWithParticipants\n      }\n    }\n  }\n":
    types.RemoveParticipantFromClassRoomDocument,
  "\n  mutation swapTeacher(\n    $classRoomId: String!\n    $teacherId: String!\n    $doNotMakeOldTeacherAvailableAgain: Boolean!\n  ) {\n    swapTeacher(\n      classRoomId: $classRoomId\n      teacherToAddId: $teacherId\n      doNotMakeOldTeacherAvailableAgain: $doNotMakeOldTeacherAvailableAgain\n    )\n  }\n":
    types.SwapTeacherDocument,
  "\n  fragment FranchiseeItem on Franchisee {\n    id\n    email\n    familyName\n    givenName\n    phoneNumber {\n      countryCode\n      number\n    }\n  }\n":
    types.FranchiseeItemFragmentDoc,
  "\n  fragment ClassRoomTeacherFragment on ClassRoomTeacher {\n    id\n    email\n    givenName\n    familyName\n    phone {\n      countryCode\n      number\n    }\n  }\n":
    types.ClassRoomTeacherFragmentFragmentDoc,
  "\n  fragment AvailabilitySlotFragment on AvailabilitySlotDto {\n    slotId\n    startDateUtc\n    endDateUtc\n    slotTimezone\n  }\n":
    types.AvailabilitySlotFragmentFragmentDoc,
  "\n  mutation removeAvailabilitySlot($slotId: String!) {\n    removeAvailabilitySlot(slotId: $slotId)\n  }\n":
    types.RemoveAvailabilitySlotDocument,
  "\n  query getAvailabilityByTeacherForWeek(\n    $fromDate: String!\n    $toDate: String!\n    $teacherId: String!\n    $timezone: String!\n  ) {\n    getAvailabilityByTeacherForWeek(\n      fromDate: $fromDate\n      toDate: $toDate\n      teacherId: $teacherId\n      timezone: $timezone\n    ) {\n      ...AvailabilitySlotFragment\n    }\n  }\n":
    types.GetAvailabilityByTeacherForWeekDocument,
  "\n  query getTeacherClassRooms(\n    $timezone: String!\n    $teacherId: String!\n    $fromDate: String!\n    $toDate: String!\n  ) {\n    getTeacherClassRooms(\n      timezone: $timezone\n      teacherId: $teacherId\n      fromDate: $fromDate\n      toDate: $toDate\n    ) {\n      ...ClassRoomWithoutParticipant\n    }\n  }\n":
    types.GetTeacherClassRoomsDocument,
  "\n  query getAllFranchisees {\n    getAllFranchisees {\n      ...FranchiseeItem\n    }\n  }\n":
    types.GetAllFranchiseesDocument,
  "\n  query getAvailableTeachersInHour($startUtc: String!, $timezone: String!) {\n    getAvailableTeachersInHour(startUtc: $startUtc, timezone: $timezone) {\n      ...ClassRoomTeacherFragment\n    }\n  }\n":
    types.GetAvailableTeachersInHourDocument,
  "\n  mutation createFranchisee(\n    $email: String!\n    $familyName: String!\n    $givenName: String!\n    $phoneNumber: PhoneNumberInput!\n  ) {\n    createFranchisee(\n      email: $email\n      familyName: $familyName\n      givenName: $givenName\n      phoneNumber: $phoneNumber\n    ) {\n      ...FranchiseeItem\n    }\n  }\n":
    types.CreateFranchiseeDocument,
  "\n  mutation deleteTeacher($teacherId: String!) {\n    deleteTeacher(teacherId: $teacherId)\n  }\n":
    types.DeleteTeacherDocument,
  "\n  query getFranchiseeById($id: String!) {\n    getFranchiseeById(id: $id) {\n      ...FranchiseeItem\n    }\n  }\n":
    types.GetFranchiseeByIdDocument,
  "\n  fragment TopicItem on Topic {\n    id\n    name\n    level\n    pdfUrl\n  }\n":
    types.TopicItemFragmentDoc,
  "\n  query getAllTopics {\n    getAllTopics {\n      ...TopicItem\n    }\n  }\n":
    types.GetAllTopicsDocument,
  "\n  mutation createTopic(\n    $nameIt: String!\n    $level: SpeakingClassLevel!\n    $pdfUrl: String!\n  ) {\n    createTopic(nameIt: $nameIt, level: $level, pdfUrl: $pdfUrl) {\n      id\n      name\n      level\n    }\n  }\n":
    types.CreateTopicDocument,
  "\n  mutation editTopic($id: String!, $nameIt: String, $pdfUrl: String) {\n    editTopic(id: $id, nameIt: $nameIt, pdfUrl: $pdfUrl) {\n      ...TopicItem\n    }\n  }\n":
    types.EditTopicDocument,
  "\n  mutation logicallyDeleteTopic($topicId: String!) {\n    logicallyDeleteTopic(id: $topicId)\n  }\n":
    types.LogicallyDeleteTopicDocument,
  "\n  query getTopicById($id: String!) {\n    getTopicById(id: $id) {\n      ...TopicItem\n    }\n  }\n":
    types.GetTopicByIdDocument,
  "\n  fragment ClassRoomPlanFragment on UserClassRoomPlan {\n    id\n    userId\n    startedAtWeekUtc\n    endedAtWeekUtc\n    lessonsPerWeek\n    numberOfSlotIncluded\n    planToRecoverId\n    warrantyConfig {\n      enabled\n      minAttendedLessons\n    }\n  }\n":
    types.ClassRoomPlanFragmentFragmentDoc,
  "\n  fragment FrozenWeek on FrozenWeekDto {\n    startOfWeekUtc\n    endOfWeekUtc\n  }\n":
    types.FrozenWeekFragmentDoc,
  "\n  fragment GetFreezableWeeksResponse on GetFreezableWeeksResponse {\n    weeks {\n      planId\n      week {\n        startOfWeekUtc\n        endOfWeekUtc\n      }\n    }\n    remainingWeeksToFreeze {\n      remainingCount\n      planId\n    }\n  }\n":
    types.GetFreezableWeeksResponseFragmentDoc,
  "\n  mutation addFrozenWeekToUser($week: FrozenWeekInput!, $userId: String!) {\n    addFrozenWeekToUser(week: $week, userId: $userId)\n  }\n":
    types.AddFrozenWeekToUserDocument,
  "\n  mutation removeFrozenWeekForUser($week: FrozenWeekInput!, $userId: String!) {\n    removeFrozenWeekForUser(week: $week, userId: $userId)\n  }\n":
    types.RemoveFrozenWeekForUserDocument,
  "\n  query getFreezableWeeksByUser($userId: String!) {\n    getFreezableWeeksByUser(userId: $userId) {\n      ...GetFreezableWeeksResponse\n    }\n  }\n":
    types.GetFreezableWeeksByUserDocument,
  "\n  query getFrozenWeeksByUser($userId: String!) {\n    getFrozenWeeksByUser(userId: $userId) {\n      ...FrozenWeek\n    }\n  }\n":
    types.GetFrozenWeeksByUserDocument,
  "\n  query calculatePlanGivenSlotByParams(\n    $endedAtWeekUtc: String!\n    $lessonsPerWeek: Int!\n    $startedAtWeekUtc: String!\n  ) {\n    calculatePlanGivenSlotByParams(\n      endedAtWeekUtc: $endedAtWeekUtc\n      lessonsPerWeek: $lessonsPerWeek\n      startedAtWeekUtc: $startedAtWeekUtc\n    )\n  }\n":
    types.CalculatePlanGivenSlotByParamsDocument,
  "\n  mutation createClassRoomPlan(\n    $userId: String!\n    $startedAtWeekUtc: String!\n    $lessonsPerWeek: Int!\n    $endedAtWeekUtc: String!\n    $planToRecoverId: String\n    $warrantyConfig: UserClassRoomPlanWarrantyConfigInputDto\n  ) {\n    createClassRoomPlan(\n      endedAtWeekUtc: $endedAtWeekUtc\n      lessonsPerWeek: $lessonsPerWeek\n      startedAtWeekUtc: $startedAtWeekUtc\n      userId: $userId\n      planToRecoverId: $planToRecoverId\n      warrantyConfig: $warrantyConfig\n    ) {\n      ...ClassRoomPlanFragment\n    }\n  }\n":
    types.CreateClassRoomPlanDocument,
  "\n  mutation deleteClassRoomPlan($planId: String!) {\n    deleteClassRoomPlan(planId: $planId)\n  }\n":
    types.DeleteClassRoomPlanDocument,
  "\n  query getClassRoomPlanByIdAndUserId($planId: String!, $userId: String!) {\n    getClassRoomPlanByIdAndUserId(planId: $planId, userId: $userId) {\n      ...ClassRoomPlanFragment\n    }\n  }\n":
    types.GetClassRoomPlanByIdAndUserIdDocument,
  "\n  mutation setUserClassRoomPlanAsRecoveryOf(\n    $planToRecoverId: String\n    $planId: String!\n    $warrantyConfig: UserClassRoomPlanWarrantyConfigInputDto\n  ) {\n    updateUserClassRoomPlan(\n      planToRecoverId: $planToRecoverId\n      planId: $planId\n      warrantyConfig: $warrantyConfig\n    ) {\n      ...ClassRoomPlanFragment\n    }\n  }\n":
    types.SetUserClassRoomPlanAsRecoveryOfDocument,
  "\n  query getUserClassRoomPlans($userId: String!) {\n    getUserClassRoomPlans(userId: $userId) {\n      ...ClassRoomPlanFragment\n    }\n  }\n":
    types.GetUserClassRoomPlansDocument,
  "\n  query getUserRemainingLessons($userId: String!) {\n    getUserRemainingLessons(userId: $userId)\n  }\n":
    types.GetUserRemainingLessonsDocument,
  "\n  fragment LessonFeedbackReportFragment on UserLessonFeedbackReport {\n    avgParticipationScore\n    avgTargetLanguageScore\n    notes {\n      classRoomStartDateUtc\n      classRoomTopicName\n      note\n    }\n    hasAtLeastOneFeedback\n  }\n":
    types.LessonFeedbackReportFragmentFragmentDoc,
  "\n  mutation deleteLessonFeedback($lessonFeedbackId: String!) {\n    deleteLessonFeedback(lessonFeedbackId: $lessonFeedbackId)\n  }\n":
    types.DeleteLessonFeedbackDocument,
  "\n  query getLessonFeedbackReportByUser($userId: String!) {\n    getLessonFeedbackReportByUser(userId: $userId) {\n      ...LessonFeedbackReportFragment\n    }\n  }\n":
    types.GetLessonFeedbackReportByUserDocument,
  "\n  fragment ProgressCheckResult on ProgressCheckResultDto {\n    comprehension\n    accuracy\n    vocabularyRange\n    fluency\n    notes\n    recommendation\n    userId\n    progressCheckLevel\n    progressCheckStartedAtUtc\n    passed\n  }\n":
    types.ProgressCheckResultFragmentDoc,
  "\n  fragment LevelChangeSuggestion on LevelChangeSuggestionDto {\n    id\n    suggestedLevels\n    user {\n      ...UserFragment\n    }\n  }\n":
    types.LevelChangeSuggestionFragmentDoc,
  "\n  query listNotResolvedLevelChangeSuggestions {\n    listNotResolvedLevelChangeSuggestions {\n      ...LevelChangeSuggestion\n    }\n  }\n":
    types.ListNotResolvedLevelChangeSuggestionsDocument,
  "\n  mutation resolveLevelSuggestion($suggestionId: String!) {\n    resolveLevelSuggestion(suggestionId: $suggestionId)\n  }\n":
    types.ResolveLevelSuggestionDocument,
  "\n  fragment UserFragment on User {\n    id\n    email\n    speakingLevel\n    givenName\n    familyName\n    authId\n    partecipatedProgressCheckCount\n    partecipatedSpeakingClassCount\n    lessonFeedbacks {\n      id\n      noShow\n      participation\n      targetLanguage\n      noShow\n      notes\n      classRoomEndDateUtc\n      classRoomStartDateUtc\n      teacher {\n        id\n        givenName\n        familyName\n        email\n      }\n    }\n    progressCheckResults {\n      teacher {\n        givenName\n        familyName\n        email\n        id\n      }\n      comprehension\n      accuracy\n      vocabularyRange\n      fluency\n      notes\n      recommendation\n      userId\n      progressCheckLevel\n      progressCheckStartedAtUtc\n      passed\n    }\n    hideBookProgressCheckSection\n    hideBookSpeakingClassSection\n  }\n":
    types.UserFragmentFragmentDoc,
  "\n  query getUsersEligibleForWarranty(\n    $plansEndBefore: String\n    $plansEndAfter: String\n  ) {\n    getUsersEligibleForWarranty(\n      plansEndBefore: $plansEndBefore\n      plansEndAfter: $plansEndAfter\n    ) {\n      ...UserFragment\n    }\n  }\n":
    types.GetUsersEligibleForWarrantyDocument,
  "\n  query listAllUsers(\n    $nextPageToken: String\n    $limit: Int!\n    $email: String\n    $prevPageToken: String\n    $withSpeakingLevel: [SpeakingLevel]\n  ) {\n    listAllUsers(\n      nextPageToken: $nextPageToken\n      limit: $limit\n      email: $email\n      prevPageToken: $prevPageToken\n      withSpeakingLevel: $withSpeakingLevel\n    ) {\n      users {\n        ...UserFragment\n      }\n      hasNextPage\n      nextPageToken\n      hasPrevPage\n      prevPageToken\n    }\n  }\n":
    types.ListAllUsersDocument,
  "\n  mutation createUser(\n    $speakingLevel: SpeakingLevel!\n    $email: String!\n    $givenName: String!\n    $familyName: String!\n    $activateAccount: Boolean\n    $hideBookSpeakingClassSection: Boolean\n    $hideBookProgressCheckSection: Boolean\n  ) {\n    createUser(\n      speakingLevel: $speakingLevel\n      email: $email\n      givenName: $givenName\n      familyName: $familyName\n      activateAccount: $activateAccount\n      hideBookSpeakingClassSection: $hideBookSpeakingClassSection\n      hideBookProgressCheckSection: $hideBookProgressCheckSection\n    ) {\n      ...UserFragment\n    }\n  }\n":
    types.CreateUserDocument,
  "\n  mutation enrollUserToAllPaidContent($userId: String!) {\n    enrollUserToAllPaidContent(userId: $userId)\n  }\n":
    types.EnrollUserToAllPaidContentDocument,
  "\n  query isActiveUserForAdminById($userId: String!) {\n    isActiveUserForAdminById(userId: $userId)\n  }\n":
    types.IsActiveUserForAdminByIdDocument,
  "\n  mutation updateUser(\n    $familyName: String\n    $givenName: String\n    $userId: String!\n    $speakingLevel: SpeakingLevel\n    $activateAccount: Boolean\n    $hideBookSpeakingClassSection: Boolean\n    $hideBookProgressCheckSection: Boolean\n  ) {\n    updateUser(\n      familyName: $familyName\n      givenName: $givenName\n      userId: $userId\n      speakingLevel: $speakingLevel\n      activateAccount: $activateAccount\n      hideBookSpeakingClassSection: $hideBookSpeakingClassSection\n      hideBookProgressCheckSection: $hideBookProgressCheckSection\n    ) {\n      ...UserFragment\n    }\n  }\n":
    types.UpdateUserDocument,
  "\n  query getUserById($userId: String!) {\n    getUserById(userId: $userId) {\n      ...UserFragment\n    }\n  }\n":
    types.GetUserByIdDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query speakingClassServiceAgGridQuery($params: AgGridParamsInput!) {\n    speakingClassServiceAgGridQuery(params: $params) {\n      hasNext\n      data\n    }\n  }\n",
): (typeof documents)["\n  query speakingClassServiceAgGridQuery($params: AgGridParamsInput!) {\n    speakingClassServiceAgGridQuery(params: $params) {\n      hasNext\n      data\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ClassRoomListItem on ClassRoomListItem {\n    id\n    startedAtUtc\n    endedAtUtc\n    type\n    speakingLevel\n    participantsCount\n    capacity\n    topic {\n      id\n      name\n    }\n    teacher {\n      id\n      givenName\n      familyName\n      phone {\n        countryCode\n        number\n      }\n      email\n    }\n    completedFeedbacksCount\n  }\n",
): (typeof documents)["\n  fragment ClassRoomListItem on ClassRoomListItem {\n    id\n    startedAtUtc\n    endedAtUtc\n    type\n    speakingLevel\n    participantsCount\n    capacity\n    topic {\n      id\n      name\n    }\n    teacher {\n      id\n      givenName\n      familyName\n      phone {\n        countryCode\n        number\n      }\n      email\n    }\n    completedFeedbacksCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ClassRoomWithParticipants on ClassRoom {\n    id\n    speakingLevel\n    startedAtUtc\n    endedAtUtc\n    topic {\n      name\n    }\n    participants {\n      id\n      givenName\n      familyName\n    }\n    capacity\n  }\n",
): (typeof documents)["\n  fragment ClassRoomWithParticipants on ClassRoom {\n    id\n    speakingLevel\n    startedAtUtc\n    endedAtUtc\n    topic {\n      name\n    }\n    participants {\n      id\n      givenName\n      familyName\n    }\n    capacity\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ClassRoomWithoutParticipant on ClassRoomWithoutParticipantDto {\n    id\n    startedAtUtc\n    endedAtUtc\n    speakingLevel\n    topic {\n      name\n    }\n    participantsCount\n    classRoomTimezone\n    type\n  }\n",
): (typeof documents)["\n  fragment ClassRoomWithoutParticipant on ClassRoomWithoutParticipantDto {\n    id\n    startedAtUtc\n    endedAtUtc\n    speakingLevel\n    topic {\n      name\n    }\n    participantsCount\n    classRoomTimezone\n    type\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment CompleteClassRoom on CompleteClassRoom {\n    id\n    speakingLevel\n    startedAtUtc\n    endedAtUtc\n    topic {\n      name\n    }\n    participants {\n      id\n      givenName\n      familyName\n      email\n    }\n    capacity\n    teacher {\n      email\n      id\n      familyName\n      givenName\n    }\n    conferenceUri\n    feedbackUrl\n    feedbackReportUrl\n  }\n",
): (typeof documents)["\n  fragment CompleteClassRoom on CompleteClassRoom {\n    id\n    speakingLevel\n    startedAtUtc\n    endedAtUtc\n    topic {\n      name\n    }\n    participants {\n      id\n      givenName\n      familyName\n      email\n    }\n    capacity\n    teacher {\n      email\n      id\n      familyName\n      givenName\n    }\n    conferenceUri\n    feedbackUrl\n    feedbackReportUrl\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query GetMinUsedTopicInLevelAmongParticipants(\n    $participantIds: [String!]!\n    $speakingLevel: SpeakingClassLevel!\n  ) {\n    getMinUsedTopicsInLevelAmongParticipants(\n      participantIds: $participantIds\n      speakingLevel: $speakingLevel\n    ) {\n      ...TopicItem\n    }\n  }\n",
): (typeof documents)["\n  query GetMinUsedTopicInLevelAmongParticipants(\n    $participantIds: [String!]!\n    $speakingLevel: SpeakingClassLevel!\n  ) {\n    getMinUsedTopicsInLevelAmongParticipants(\n      participantIds: $participantIds\n      speakingLevel: $speakingLevel\n    ) {\n      ...TopicItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation createClassRoom(\n    $capacity: Int!\n    $classRoomType: ClassRoomType!\n    $participantIds: [String!]!\n    $speakingLevel: SpeakingClassLevel!\n    $startUtc: String!\n    $teacherId: String!\n    $topicId: String!\n  ) {\n    createClassRoom(\n      capacity: $capacity\n      classRoomType: $classRoomType\n      participantIds: $participantIds\n      speakingLevel: $speakingLevel\n      startUtc: $startUtc\n      teacherId: $teacherId\n      topicId: $topicId\n    ) {\n      id\n    }\n  }\n",
): (typeof documents)["\n  mutation createClassRoom(\n    $capacity: Int!\n    $classRoomType: ClassRoomType!\n    $participantIds: [String!]!\n    $speakingLevel: SpeakingClassLevel!\n    $startUtc: String!\n    $teacherId: String!\n    $topicId: String!\n  ) {\n    createClassRoom(\n      capacity: $capacity\n      classRoomType: $classRoomType\n      participantIds: $participantIds\n      speakingLevel: $speakingLevel\n      startUtc: $startUtc\n      teacherId: $teacherId\n      topicId: $topicId\n    ) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation addParticipantsToClassByEmail(\n    $classRoomId: String!\n    $emails: [String!]!\n  ) {\n    addParticipantsToClassByEmail(\n      classRoomId: $classRoomId\n      userEmails: $emails\n    ) {\n      ...ClassRoomWithParticipants\n    }\n  }\n",
): (typeof documents)["\n  mutation addParticipantsToClassByEmail(\n    $classRoomId: String!\n    $emails: [String!]!\n  ) {\n    addParticipantsToClassByEmail(\n      classRoomId: $classRoomId\n      userEmails: $emails\n    ) {\n      ...ClassRoomWithParticipants\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query adminGetClassRoomById($id: String!) {\n    adminGetClassRoomById(classRoomId: $id) {\n      ...CompleteClassRoom\n    }\n  }\n",
): (typeof documents)["\n  query adminGetClassRoomById($id: String!) {\n    adminGetClassRoomById(classRoomId: $id) {\n      ...CompleteClassRoom\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query listClassRooms(\n    $limit: Int!\n    $nextToken: String\n    $prevToken: String\n    $dateRange: ListClassRoomDateInput\n  ) {\n    listClassRooms(\n      limit: $limit\n      nextToken: $nextToken\n      prevToken: $prevToken\n      dateRange: $dateRange\n    ) {\n      classRooms {\n        ...ClassRoomListItem\n      }\n      hasNextPage\n      hasPrevPage\n      nextPageToken\n      prevPageToken\n    }\n  }\n",
): (typeof documents)["\n  query listClassRooms(\n    $limit: Int!\n    $nextToken: String\n    $prevToken: String\n    $dateRange: ListClassRoomDateInput\n  ) {\n    listClassRooms(\n      limit: $limit\n      nextToken: $nextToken\n      prevToken: $prevToken\n      dateRange: $dateRange\n    ) {\n      classRooms {\n        ...ClassRoomListItem\n      }\n      hasNextPage\n      hasPrevPage\n      nextPageToken\n      prevPageToken\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation deleteClassRoom($classRoomId: String!) {\n    deleteClassRoom(classRoomId: $classRoomId)\n  }\n",
): (typeof documents)["\n  mutation deleteClassRoom($classRoomId: String!) {\n    deleteClassRoom(classRoomId: $classRoomId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation removeParticipantFromClassRoom(\n    $classRoomId: String!\n    $userId: String!\n  ) {\n    removeParticipantFromClassRoom(classRoomId: $classRoomId, userId: $userId) {\n      classRoomDeleted\n      classRoom {\n        ...ClassRoomWithParticipants\n      }\n    }\n  }\n",
): (typeof documents)["\n  mutation removeParticipantFromClassRoom(\n    $classRoomId: String!\n    $userId: String!\n  ) {\n    removeParticipantFromClassRoom(classRoomId: $classRoomId, userId: $userId) {\n      classRoomDeleted\n      classRoom {\n        ...ClassRoomWithParticipants\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation swapTeacher(\n    $classRoomId: String!\n    $teacherId: String!\n    $doNotMakeOldTeacherAvailableAgain: Boolean!\n  ) {\n    swapTeacher(\n      classRoomId: $classRoomId\n      teacherToAddId: $teacherId\n      doNotMakeOldTeacherAvailableAgain: $doNotMakeOldTeacherAvailableAgain\n    )\n  }\n",
): (typeof documents)["\n  mutation swapTeacher(\n    $classRoomId: String!\n    $teacherId: String!\n    $doNotMakeOldTeacherAvailableAgain: Boolean!\n  ) {\n    swapTeacher(\n      classRoomId: $classRoomId\n      teacherToAddId: $teacherId\n      doNotMakeOldTeacherAvailableAgain: $doNotMakeOldTeacherAvailableAgain\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment FranchiseeItem on Franchisee {\n    id\n    email\n    familyName\n    givenName\n    phoneNumber {\n      countryCode\n      number\n    }\n  }\n",
): (typeof documents)["\n  fragment FranchiseeItem on Franchisee {\n    id\n    email\n    familyName\n    givenName\n    phoneNumber {\n      countryCode\n      number\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ClassRoomTeacherFragment on ClassRoomTeacher {\n    id\n    email\n    givenName\n    familyName\n    phone {\n      countryCode\n      number\n    }\n  }\n",
): (typeof documents)["\n  fragment ClassRoomTeacherFragment on ClassRoomTeacher {\n    id\n    email\n    givenName\n    familyName\n    phone {\n      countryCode\n      number\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment AvailabilitySlotFragment on AvailabilitySlotDto {\n    slotId\n    startDateUtc\n    endDateUtc\n    slotTimezone\n  }\n",
): (typeof documents)["\n  fragment AvailabilitySlotFragment on AvailabilitySlotDto {\n    slotId\n    startDateUtc\n    endDateUtc\n    slotTimezone\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation removeAvailabilitySlot($slotId: String!) {\n    removeAvailabilitySlot(slotId: $slotId)\n  }\n",
): (typeof documents)["\n  mutation removeAvailabilitySlot($slotId: String!) {\n    removeAvailabilitySlot(slotId: $slotId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getAvailabilityByTeacherForWeek(\n    $fromDate: String!\n    $toDate: String!\n    $teacherId: String!\n    $timezone: String!\n  ) {\n    getAvailabilityByTeacherForWeek(\n      fromDate: $fromDate\n      toDate: $toDate\n      teacherId: $teacherId\n      timezone: $timezone\n    ) {\n      ...AvailabilitySlotFragment\n    }\n  }\n",
): (typeof documents)["\n  query getAvailabilityByTeacherForWeek(\n    $fromDate: String!\n    $toDate: String!\n    $teacherId: String!\n    $timezone: String!\n  ) {\n    getAvailabilityByTeacherForWeek(\n      fromDate: $fromDate\n      toDate: $toDate\n      teacherId: $teacherId\n      timezone: $timezone\n    ) {\n      ...AvailabilitySlotFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getTeacherClassRooms(\n    $timezone: String!\n    $teacherId: String!\n    $fromDate: String!\n    $toDate: String!\n  ) {\n    getTeacherClassRooms(\n      timezone: $timezone\n      teacherId: $teacherId\n      fromDate: $fromDate\n      toDate: $toDate\n    ) {\n      ...ClassRoomWithoutParticipant\n    }\n  }\n",
): (typeof documents)["\n  query getTeacherClassRooms(\n    $timezone: String!\n    $teacherId: String!\n    $fromDate: String!\n    $toDate: String!\n  ) {\n    getTeacherClassRooms(\n      timezone: $timezone\n      teacherId: $teacherId\n      fromDate: $fromDate\n      toDate: $toDate\n    ) {\n      ...ClassRoomWithoutParticipant\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getAllFranchisees {\n    getAllFranchisees {\n      ...FranchiseeItem\n    }\n  }\n",
): (typeof documents)["\n  query getAllFranchisees {\n    getAllFranchisees {\n      ...FranchiseeItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getAvailableTeachersInHour($startUtc: String!, $timezone: String!) {\n    getAvailableTeachersInHour(startUtc: $startUtc, timezone: $timezone) {\n      ...ClassRoomTeacherFragment\n    }\n  }\n",
): (typeof documents)["\n  query getAvailableTeachersInHour($startUtc: String!, $timezone: String!) {\n    getAvailableTeachersInHour(startUtc: $startUtc, timezone: $timezone) {\n      ...ClassRoomTeacherFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation createFranchisee(\n    $email: String!\n    $familyName: String!\n    $givenName: String!\n    $phoneNumber: PhoneNumberInput!\n  ) {\n    createFranchisee(\n      email: $email\n      familyName: $familyName\n      givenName: $givenName\n      phoneNumber: $phoneNumber\n    ) {\n      ...FranchiseeItem\n    }\n  }\n",
): (typeof documents)["\n  mutation createFranchisee(\n    $email: String!\n    $familyName: String!\n    $givenName: String!\n    $phoneNumber: PhoneNumberInput!\n  ) {\n    createFranchisee(\n      email: $email\n      familyName: $familyName\n      givenName: $givenName\n      phoneNumber: $phoneNumber\n    ) {\n      ...FranchiseeItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation deleteTeacher($teacherId: String!) {\n    deleteTeacher(teacherId: $teacherId)\n  }\n",
): (typeof documents)["\n  mutation deleteTeacher($teacherId: String!) {\n    deleteTeacher(teacherId: $teacherId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getFranchiseeById($id: String!) {\n    getFranchiseeById(id: $id) {\n      ...FranchiseeItem\n    }\n  }\n",
): (typeof documents)["\n  query getFranchiseeById($id: String!) {\n    getFranchiseeById(id: $id) {\n      ...FranchiseeItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment TopicItem on Topic {\n    id\n    name\n    level\n    pdfUrl\n  }\n",
): (typeof documents)["\n  fragment TopicItem on Topic {\n    id\n    name\n    level\n    pdfUrl\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getAllTopics {\n    getAllTopics {\n      ...TopicItem\n    }\n  }\n",
): (typeof documents)["\n  query getAllTopics {\n    getAllTopics {\n      ...TopicItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation createTopic(\n    $nameIt: String!\n    $level: SpeakingClassLevel!\n    $pdfUrl: String!\n  ) {\n    createTopic(nameIt: $nameIt, level: $level, pdfUrl: $pdfUrl) {\n      id\n      name\n      level\n    }\n  }\n",
): (typeof documents)["\n  mutation createTopic(\n    $nameIt: String!\n    $level: SpeakingClassLevel!\n    $pdfUrl: String!\n  ) {\n    createTopic(nameIt: $nameIt, level: $level, pdfUrl: $pdfUrl) {\n      id\n      name\n      level\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation editTopic($id: String!, $nameIt: String, $pdfUrl: String) {\n    editTopic(id: $id, nameIt: $nameIt, pdfUrl: $pdfUrl) {\n      ...TopicItem\n    }\n  }\n",
): (typeof documents)["\n  mutation editTopic($id: String!, $nameIt: String, $pdfUrl: String) {\n    editTopic(id: $id, nameIt: $nameIt, pdfUrl: $pdfUrl) {\n      ...TopicItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation logicallyDeleteTopic($topicId: String!) {\n    logicallyDeleteTopic(id: $topicId)\n  }\n",
): (typeof documents)["\n  mutation logicallyDeleteTopic($topicId: String!) {\n    logicallyDeleteTopic(id: $topicId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getTopicById($id: String!) {\n    getTopicById(id: $id) {\n      ...TopicItem\n    }\n  }\n",
): (typeof documents)["\n  query getTopicById($id: String!) {\n    getTopicById(id: $id) {\n      ...TopicItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ClassRoomPlanFragment on UserClassRoomPlan {\n    id\n    userId\n    startedAtWeekUtc\n    endedAtWeekUtc\n    lessonsPerWeek\n    numberOfSlotIncluded\n    planToRecoverId\n    warrantyConfig {\n      enabled\n      minAttendedLessons\n    }\n  }\n",
): (typeof documents)["\n  fragment ClassRoomPlanFragment on UserClassRoomPlan {\n    id\n    userId\n    startedAtWeekUtc\n    endedAtWeekUtc\n    lessonsPerWeek\n    numberOfSlotIncluded\n    planToRecoverId\n    warrantyConfig {\n      enabled\n      minAttendedLessons\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment FrozenWeek on FrozenWeekDto {\n    startOfWeekUtc\n    endOfWeekUtc\n  }\n",
): (typeof documents)["\n  fragment FrozenWeek on FrozenWeekDto {\n    startOfWeekUtc\n    endOfWeekUtc\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment GetFreezableWeeksResponse on GetFreezableWeeksResponse {\n    weeks {\n      planId\n      week {\n        startOfWeekUtc\n        endOfWeekUtc\n      }\n    }\n    remainingWeeksToFreeze {\n      remainingCount\n      planId\n    }\n  }\n",
): (typeof documents)["\n  fragment GetFreezableWeeksResponse on GetFreezableWeeksResponse {\n    weeks {\n      planId\n      week {\n        startOfWeekUtc\n        endOfWeekUtc\n      }\n    }\n    remainingWeeksToFreeze {\n      remainingCount\n      planId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation addFrozenWeekToUser($week: FrozenWeekInput!, $userId: String!) {\n    addFrozenWeekToUser(week: $week, userId: $userId)\n  }\n",
): (typeof documents)["\n  mutation addFrozenWeekToUser($week: FrozenWeekInput!, $userId: String!) {\n    addFrozenWeekToUser(week: $week, userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation removeFrozenWeekForUser($week: FrozenWeekInput!, $userId: String!) {\n    removeFrozenWeekForUser(week: $week, userId: $userId)\n  }\n",
): (typeof documents)["\n  mutation removeFrozenWeekForUser($week: FrozenWeekInput!, $userId: String!) {\n    removeFrozenWeekForUser(week: $week, userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getFreezableWeeksByUser($userId: String!) {\n    getFreezableWeeksByUser(userId: $userId) {\n      ...GetFreezableWeeksResponse\n    }\n  }\n",
): (typeof documents)["\n  query getFreezableWeeksByUser($userId: String!) {\n    getFreezableWeeksByUser(userId: $userId) {\n      ...GetFreezableWeeksResponse\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getFrozenWeeksByUser($userId: String!) {\n    getFrozenWeeksByUser(userId: $userId) {\n      ...FrozenWeek\n    }\n  }\n",
): (typeof documents)["\n  query getFrozenWeeksByUser($userId: String!) {\n    getFrozenWeeksByUser(userId: $userId) {\n      ...FrozenWeek\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query calculatePlanGivenSlotByParams(\n    $endedAtWeekUtc: String!\n    $lessonsPerWeek: Int!\n    $startedAtWeekUtc: String!\n  ) {\n    calculatePlanGivenSlotByParams(\n      endedAtWeekUtc: $endedAtWeekUtc\n      lessonsPerWeek: $lessonsPerWeek\n      startedAtWeekUtc: $startedAtWeekUtc\n    )\n  }\n",
): (typeof documents)["\n  query calculatePlanGivenSlotByParams(\n    $endedAtWeekUtc: String!\n    $lessonsPerWeek: Int!\n    $startedAtWeekUtc: String!\n  ) {\n    calculatePlanGivenSlotByParams(\n      endedAtWeekUtc: $endedAtWeekUtc\n      lessonsPerWeek: $lessonsPerWeek\n      startedAtWeekUtc: $startedAtWeekUtc\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation createClassRoomPlan(\n    $userId: String!\n    $startedAtWeekUtc: String!\n    $lessonsPerWeek: Int!\n    $endedAtWeekUtc: String!\n    $planToRecoverId: String\n    $warrantyConfig: UserClassRoomPlanWarrantyConfigInputDto\n  ) {\n    createClassRoomPlan(\n      endedAtWeekUtc: $endedAtWeekUtc\n      lessonsPerWeek: $lessonsPerWeek\n      startedAtWeekUtc: $startedAtWeekUtc\n      userId: $userId\n      planToRecoverId: $planToRecoverId\n      warrantyConfig: $warrantyConfig\n    ) {\n      ...ClassRoomPlanFragment\n    }\n  }\n",
): (typeof documents)["\n  mutation createClassRoomPlan(\n    $userId: String!\n    $startedAtWeekUtc: String!\n    $lessonsPerWeek: Int!\n    $endedAtWeekUtc: String!\n    $planToRecoverId: String\n    $warrantyConfig: UserClassRoomPlanWarrantyConfigInputDto\n  ) {\n    createClassRoomPlan(\n      endedAtWeekUtc: $endedAtWeekUtc\n      lessonsPerWeek: $lessonsPerWeek\n      startedAtWeekUtc: $startedAtWeekUtc\n      userId: $userId\n      planToRecoverId: $planToRecoverId\n      warrantyConfig: $warrantyConfig\n    ) {\n      ...ClassRoomPlanFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation deleteClassRoomPlan($planId: String!) {\n    deleteClassRoomPlan(planId: $planId)\n  }\n",
): (typeof documents)["\n  mutation deleteClassRoomPlan($planId: String!) {\n    deleteClassRoomPlan(planId: $planId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getClassRoomPlanByIdAndUserId($planId: String!, $userId: String!) {\n    getClassRoomPlanByIdAndUserId(planId: $planId, userId: $userId) {\n      ...ClassRoomPlanFragment\n    }\n  }\n",
): (typeof documents)["\n  query getClassRoomPlanByIdAndUserId($planId: String!, $userId: String!) {\n    getClassRoomPlanByIdAndUserId(planId: $planId, userId: $userId) {\n      ...ClassRoomPlanFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation setUserClassRoomPlanAsRecoveryOf(\n    $planToRecoverId: String\n    $planId: String!\n    $warrantyConfig: UserClassRoomPlanWarrantyConfigInputDto\n  ) {\n    updateUserClassRoomPlan(\n      planToRecoverId: $planToRecoverId\n      planId: $planId\n      warrantyConfig: $warrantyConfig\n    ) {\n      ...ClassRoomPlanFragment\n    }\n  }\n",
): (typeof documents)["\n  mutation setUserClassRoomPlanAsRecoveryOf(\n    $planToRecoverId: String\n    $planId: String!\n    $warrantyConfig: UserClassRoomPlanWarrantyConfigInputDto\n  ) {\n    updateUserClassRoomPlan(\n      planToRecoverId: $planToRecoverId\n      planId: $planId\n      warrantyConfig: $warrantyConfig\n    ) {\n      ...ClassRoomPlanFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getUserClassRoomPlans($userId: String!) {\n    getUserClassRoomPlans(userId: $userId) {\n      ...ClassRoomPlanFragment\n    }\n  }\n",
): (typeof documents)["\n  query getUserClassRoomPlans($userId: String!) {\n    getUserClassRoomPlans(userId: $userId) {\n      ...ClassRoomPlanFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getUserRemainingLessons($userId: String!) {\n    getUserRemainingLessons(userId: $userId)\n  }\n",
): (typeof documents)["\n  query getUserRemainingLessons($userId: String!) {\n    getUserRemainingLessons(userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment LessonFeedbackReportFragment on UserLessonFeedbackReport {\n    avgParticipationScore\n    avgTargetLanguageScore\n    notes {\n      classRoomStartDateUtc\n      classRoomTopicName\n      note\n    }\n    hasAtLeastOneFeedback\n  }\n",
): (typeof documents)["\n  fragment LessonFeedbackReportFragment on UserLessonFeedbackReport {\n    avgParticipationScore\n    avgTargetLanguageScore\n    notes {\n      classRoomStartDateUtc\n      classRoomTopicName\n      note\n    }\n    hasAtLeastOneFeedback\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation deleteLessonFeedback($lessonFeedbackId: String!) {\n    deleteLessonFeedback(lessonFeedbackId: $lessonFeedbackId)\n  }\n",
): (typeof documents)["\n  mutation deleteLessonFeedback($lessonFeedbackId: String!) {\n    deleteLessonFeedback(lessonFeedbackId: $lessonFeedbackId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getLessonFeedbackReportByUser($userId: String!) {\n    getLessonFeedbackReportByUser(userId: $userId) {\n      ...LessonFeedbackReportFragment\n    }\n  }\n",
): (typeof documents)["\n  query getLessonFeedbackReportByUser($userId: String!) {\n    getLessonFeedbackReportByUser(userId: $userId) {\n      ...LessonFeedbackReportFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ProgressCheckResult on ProgressCheckResultDto {\n    comprehension\n    accuracy\n    vocabularyRange\n    fluency\n    notes\n    recommendation\n    userId\n    progressCheckLevel\n    progressCheckStartedAtUtc\n    passed\n  }\n",
): (typeof documents)["\n  fragment ProgressCheckResult on ProgressCheckResultDto {\n    comprehension\n    accuracy\n    vocabularyRange\n    fluency\n    notes\n    recommendation\n    userId\n    progressCheckLevel\n    progressCheckStartedAtUtc\n    passed\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment LevelChangeSuggestion on LevelChangeSuggestionDto {\n    id\n    suggestedLevels\n    user {\n      ...UserFragment\n    }\n  }\n",
): (typeof documents)["\n  fragment LevelChangeSuggestion on LevelChangeSuggestionDto {\n    id\n    suggestedLevels\n    user {\n      ...UserFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query listNotResolvedLevelChangeSuggestions {\n    listNotResolvedLevelChangeSuggestions {\n      ...LevelChangeSuggestion\n    }\n  }\n",
): (typeof documents)["\n  query listNotResolvedLevelChangeSuggestions {\n    listNotResolvedLevelChangeSuggestions {\n      ...LevelChangeSuggestion\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation resolveLevelSuggestion($suggestionId: String!) {\n    resolveLevelSuggestion(suggestionId: $suggestionId)\n  }\n",
): (typeof documents)["\n  mutation resolveLevelSuggestion($suggestionId: String!) {\n    resolveLevelSuggestion(suggestionId: $suggestionId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment UserFragment on User {\n    id\n    email\n    speakingLevel\n    givenName\n    familyName\n    authId\n    partecipatedProgressCheckCount\n    partecipatedSpeakingClassCount\n    lessonFeedbacks {\n      id\n      noShow\n      participation\n      targetLanguage\n      noShow\n      notes\n      classRoomEndDateUtc\n      classRoomStartDateUtc\n      teacher {\n        id\n        givenName\n        familyName\n        email\n      }\n    }\n    progressCheckResults {\n      teacher {\n        givenName\n        familyName\n        email\n        id\n      }\n      comprehension\n      accuracy\n      vocabularyRange\n      fluency\n      notes\n      recommendation\n      userId\n      progressCheckLevel\n      progressCheckStartedAtUtc\n      passed\n    }\n    hideBookProgressCheckSection\n    hideBookSpeakingClassSection\n  }\n",
): (typeof documents)["\n  fragment UserFragment on User {\n    id\n    email\n    speakingLevel\n    givenName\n    familyName\n    authId\n    partecipatedProgressCheckCount\n    partecipatedSpeakingClassCount\n    lessonFeedbacks {\n      id\n      noShow\n      participation\n      targetLanguage\n      noShow\n      notes\n      classRoomEndDateUtc\n      classRoomStartDateUtc\n      teacher {\n        id\n        givenName\n        familyName\n        email\n      }\n    }\n    progressCheckResults {\n      teacher {\n        givenName\n        familyName\n        email\n        id\n      }\n      comprehension\n      accuracy\n      vocabularyRange\n      fluency\n      notes\n      recommendation\n      userId\n      progressCheckLevel\n      progressCheckStartedAtUtc\n      passed\n    }\n    hideBookProgressCheckSection\n    hideBookSpeakingClassSection\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getUsersEligibleForWarranty(\n    $plansEndBefore: String\n    $plansEndAfter: String\n  ) {\n    getUsersEligibleForWarranty(\n      plansEndBefore: $plansEndBefore\n      plansEndAfter: $plansEndAfter\n    ) {\n      ...UserFragment\n    }\n  }\n",
): (typeof documents)["\n  query getUsersEligibleForWarranty(\n    $plansEndBefore: String\n    $plansEndAfter: String\n  ) {\n    getUsersEligibleForWarranty(\n      plansEndBefore: $plansEndBefore\n      plansEndAfter: $plansEndAfter\n    ) {\n      ...UserFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query listAllUsers(\n    $nextPageToken: String\n    $limit: Int!\n    $email: String\n    $prevPageToken: String\n    $withSpeakingLevel: [SpeakingLevel]\n  ) {\n    listAllUsers(\n      nextPageToken: $nextPageToken\n      limit: $limit\n      email: $email\n      prevPageToken: $prevPageToken\n      withSpeakingLevel: $withSpeakingLevel\n    ) {\n      users {\n        ...UserFragment\n      }\n      hasNextPage\n      nextPageToken\n      hasPrevPage\n      prevPageToken\n    }\n  }\n",
): (typeof documents)["\n  query listAllUsers(\n    $nextPageToken: String\n    $limit: Int!\n    $email: String\n    $prevPageToken: String\n    $withSpeakingLevel: [SpeakingLevel]\n  ) {\n    listAllUsers(\n      nextPageToken: $nextPageToken\n      limit: $limit\n      email: $email\n      prevPageToken: $prevPageToken\n      withSpeakingLevel: $withSpeakingLevel\n    ) {\n      users {\n        ...UserFragment\n      }\n      hasNextPage\n      nextPageToken\n      hasPrevPage\n      prevPageToken\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation createUser(\n    $speakingLevel: SpeakingLevel!\n    $email: String!\n    $givenName: String!\n    $familyName: String!\n    $activateAccount: Boolean\n    $hideBookSpeakingClassSection: Boolean\n    $hideBookProgressCheckSection: Boolean\n  ) {\n    createUser(\n      speakingLevel: $speakingLevel\n      email: $email\n      givenName: $givenName\n      familyName: $familyName\n      activateAccount: $activateAccount\n      hideBookSpeakingClassSection: $hideBookSpeakingClassSection\n      hideBookProgressCheckSection: $hideBookProgressCheckSection\n    ) {\n      ...UserFragment\n    }\n  }\n",
): (typeof documents)["\n  mutation createUser(\n    $speakingLevel: SpeakingLevel!\n    $email: String!\n    $givenName: String!\n    $familyName: String!\n    $activateAccount: Boolean\n    $hideBookSpeakingClassSection: Boolean\n    $hideBookProgressCheckSection: Boolean\n  ) {\n    createUser(\n      speakingLevel: $speakingLevel\n      email: $email\n      givenName: $givenName\n      familyName: $familyName\n      activateAccount: $activateAccount\n      hideBookSpeakingClassSection: $hideBookSpeakingClassSection\n      hideBookProgressCheckSection: $hideBookProgressCheckSection\n    ) {\n      ...UserFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation enrollUserToAllPaidContent($userId: String!) {\n    enrollUserToAllPaidContent(userId: $userId)\n  }\n",
): (typeof documents)["\n  mutation enrollUserToAllPaidContent($userId: String!) {\n    enrollUserToAllPaidContent(userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query isActiveUserForAdminById($userId: String!) {\n    isActiveUserForAdminById(userId: $userId)\n  }\n",
): (typeof documents)["\n  query isActiveUserForAdminById($userId: String!) {\n    isActiveUserForAdminById(userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation updateUser(\n    $familyName: String\n    $givenName: String\n    $userId: String!\n    $speakingLevel: SpeakingLevel\n    $activateAccount: Boolean\n    $hideBookSpeakingClassSection: Boolean\n    $hideBookProgressCheckSection: Boolean\n  ) {\n    updateUser(\n      familyName: $familyName\n      givenName: $givenName\n      userId: $userId\n      speakingLevel: $speakingLevel\n      activateAccount: $activateAccount\n      hideBookSpeakingClassSection: $hideBookSpeakingClassSection\n      hideBookProgressCheckSection: $hideBookProgressCheckSection\n    ) {\n      ...UserFragment\n    }\n  }\n",
): (typeof documents)["\n  mutation updateUser(\n    $familyName: String\n    $givenName: String\n    $userId: String!\n    $speakingLevel: SpeakingLevel\n    $activateAccount: Boolean\n    $hideBookSpeakingClassSection: Boolean\n    $hideBookProgressCheckSection: Boolean\n  ) {\n    updateUser(\n      familyName: $familyName\n      givenName: $givenName\n      userId: $userId\n      speakingLevel: $speakingLevel\n      activateAccount: $activateAccount\n      hideBookSpeakingClassSection: $hideBookSpeakingClassSection\n      hideBookProgressCheckSection: $hideBookProgressCheckSection\n    ) {\n      ...UserFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getUserById($userId: String!) {\n    getUserById(userId: $userId) {\n      ...UserFragment\n    }\n  }\n",
): (typeof documents)["\n  query getUserById($userId: String!) {\n    getUserById(userId: $userId) {\n      ...UserFragment\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
