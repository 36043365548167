/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
};

export type AgGridParamsInput = {
  endRow: Scalars["Int"]["input"];
  filterModel: Scalars["JSON"]["input"];
  groupKeys: Array<Scalars["String"]["input"]>;
  queryId: Scalars["String"]["input"];
  rowGroupCols: Scalars["JSON"]["input"];
  sortModel: Scalars["JSON"]["input"];
  startRow: Scalars["Int"]["input"];
  valueCols: Scalars["JSON"]["input"];
};

export type AgGridQueryResponse = {
  data: Scalars["JSON"]["output"];
  hasNext: Scalars["Boolean"]["output"];
};

export type AvailabilitySlotDto = {
  endDateUtc: Scalars["String"]["output"];
  slotId: Scalars["String"]["output"];
  slotTimezone: Scalars["String"]["output"];
  startDateUtc: Scalars["String"]["output"];
};

export type BookSlotClassRoomInput = {
  id: Scalars["String"]["input"];
};

export type BookSlotInput = {
  classRoom?: InputMaybe<BookSlotClassRoomInput>;
  endDateUtc: Scalars["String"]["input"];
  slotId: Scalars["String"]["input"];
  startDateUtc: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
  topicId: Scalars["String"]["input"];
};

export type BookableSlot = {
  capacity: Scalars["Int"]["output"];
  classRoom?: Maybe<BookableSlotClassRoom>;
  endDateUtc: Scalars["String"]["output"];
  hasEnoughRemainingLessons: Scalars["Boolean"]["output"];
  participantsCount: Scalars["Int"]["output"];
  slotId: Scalars["ID"]["output"];
  slotLevel: SpeakingClassLevel;
  slotTimezone: Scalars["String"]["output"];
  slotType: ClassRoomType;
  startDateUtc: Scalars["String"]["output"];
  topic: Topic;
  userIsAParticipant: Scalars["Boolean"]["output"];
};

export type BookableSlotClassRoom = {
  canBeLeaved: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  meetingUri: Scalars["String"]["output"];
};

export enum CacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type ClassRoom = {
  capacity: Scalars["Int"]["output"];
  classRoomTimezone: Scalars["String"]["output"];
  endedAtUtc: Scalars["String"]["output"];
  feedbackReportUrl: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  meetingUrl: Scalars["String"]["output"];
  participants: Array<ClassRoomParticipant>;
  speakingLevel: SpeakingClassLevel;
  startedAtUtc: Scalars["String"]["output"];
  topic: Topic;
};

export type ClassRoomListItem = {
  capacity: Scalars["Float"]["output"];
  classRoomTimezone: Scalars["String"]["output"];
  completedFeedbacksCount: Scalars["Int"]["output"];
  endedAtUtc: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  meetingUri: Scalars["String"]["output"];
  participantsCount: Scalars["Float"]["output"];
  speakingLevel: SpeakingClassLevel;
  startedAtUtc: Scalars["String"]["output"];
  teacher: ClassRoomTeacher;
  topic: Topic;
  type: ClassRoomType;
};

export type ClassRoomParticipant = {
  executedClassRoomCount: Scalars["Int"]["output"];
  familyName: Scalars["String"]["output"];
  givenName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  speakingLevel: SpeakingClassLevel;
};

export type ClassRoomReviewDto = {
  classRoom: ClassRoomWithoutParticipantDto;
  classRoomId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  reviewNotes: Scalars["String"]["output"];
  reviewedAtUtc: Scalars["String"]["output"];
  teacherId: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
};

export type ClassRoomTeacher = {
  email: Scalars["String"]["output"];
  familyName: Scalars["String"]["output"];
  givenName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  phone?: Maybe<PhoneNumber>;
};

/** The class room type such as speaking class or exam */
export enum ClassRoomType {
  Exam = "Exam",
  SpeakingClass = "SpeakingClass",
}

export type ClassRoomV2 = {
  canBeLeaved: Scalars["Boolean"]["output"];
  capacity: Scalars["Float"]["output"];
  classRoomTimezone: Scalars["String"]["output"];
  endedAtUtc: Scalars["String"]["output"];
  feedbackReportUri: Scalars["String"]["output"];
  feedbackUri: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  meetingUri: Scalars["String"]["output"];
  participantsCount: Scalars["Float"]["output"];
  progressCheckTopic?: Maybe<ProgressCheckTopic>;
  speakingLevel: SpeakingClassLevel;
  startedAtUtc: Scalars["String"]["output"];
  teacher: Franchisee;
  teacherId: Scalars["String"]["output"];
  topic: Topic;
  type: ClassRoomType;
};

export type ClassRoomWithoutParticipantDto = {
  canBeLeaved: Scalars["Boolean"]["output"];
  capacity: Scalars["Float"]["output"];
  classRoomTimezone: Scalars["String"]["output"];
  endedAtUtc: Scalars["String"]["output"];
  feedbackReportUrl: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  meetingUri: Scalars["String"]["output"];
  participantsCount: Scalars["Float"]["output"];
  speakingLevel: SpeakingClassLevel;
  startedAtUtc: Scalars["String"]["output"];
  topic: Topic;
  type: ClassRoomType;
};

export type CompleteClassRoom = {
  capacity: Scalars["Int"]["output"];
  conferenceUri: Scalars["String"]["output"];
  endedAtUtc: Scalars["String"]["output"];
  feedbackReportUrl: Scalars["String"]["output"];
  feedbackUrl: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  meetingUrl: Scalars["String"]["output"];
  participants: Array<CompleteClassRoomParticipant>;
  speakingLevel: SpeakingClassLevel;
  startedAtUtc: Scalars["String"]["output"];
  teacher: ClassRoomTeacher;
  topic: Topic;
};

export type CompleteClassRoomParticipant = {
  email: Scalars["String"]["output"];
  familyName: Scalars["String"]["output"];
  givenName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
};

export type Franchisee = {
  email: Scalars["String"]["output"];
  familyName: Scalars["String"]["output"];
  givenName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  phoneNumber?: Maybe<PhoneNumber>;
  shiftBaseId: Scalars["String"]["output"];
};

export type FrozenWeekDto = {
  endOfWeekUtc: Scalars["String"]["output"];
  startOfWeekUtc: Scalars["String"]["output"];
};

export type FrozenWeekInput = {
  endOfWeekDate: Scalars["String"]["input"];
  startOfWeekDate: Scalars["String"]["input"];
};

export type GetFreezableWeeksResponse = {
  remainingWeeksToFreeze: Array<RemainingWeeksToFreeze>;
  weeks: Array<WeekWithPlan>;
};

export type GetMyRemainingLessonResponse = {
  remainingLessons: Scalars["Int"]["output"];
  totalAvailableLessons: Scalars["Int"]["output"];
};

export type GetProgressCheckAvailabilityGraphqlResponse = {
  hasAtLeastOnProgressCheckBookable: Scalars["Boolean"]["output"];
  slots: Array<BookableSlot>;
};

export type GetProgressCheckUserListResponse = {
  participants: Array<ProgressCheckUserDto>;
  progressCheck: ClassRoomWithoutParticipantDto;
};

export type GetSpeakingClassAvailabilityResponse = {
  slots: Array<BookableSlot>;
};

export type GetUserClassRoomCountResponse = {
  totalClassRoomCount: Scalars["Int"]["output"];
};

export type GetUserRemainingLessonCountByWeekResponse = {
  remainingLessonCount: Scalars["Int"]["output"];
  totalLessonCount: Scalars["Int"]["output"];
};

export type GiveProgressCheckFeedbackInput = {
  accuracy: Scalars["Int"]["input"];
  comprehension: Scalars["Int"]["input"];
  fluency: Scalars["Int"]["input"];
  noShow?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  passed: Scalars["Boolean"]["input"];
  progressCheckId: Scalars["String"]["input"];
  recommendation: Array<ProgressCheckRecommendation>;
  userId: Scalars["String"]["input"];
  vocabularyRange: Scalars["Int"]["input"];
};

export type HasUnlockedProgressCheckResponse = {
  hasUnlockedProgressCheck: Scalars["Boolean"]["output"];
  lessonCompletedToUnlockProgressCheck: Scalars["Int"]["output"];
  totalLessonNeededToUnlockProgressCheck: Scalars["Int"]["output"];
};

export type LevelChangeSuggestionDto = {
  id: Scalars["String"]["output"];
  suggestedLevels: Array<SpeakingClassLevel>;
  user: User;
  userId: Scalars["String"]["output"];
};

export type ListClassRoomDateInput = {
  fromDate: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type ListClassRoomResponse = {
  classRooms: Array<ClassRoomListItem>;
  hasNextPage: Scalars["Boolean"]["output"];
  hasPrevPage: Scalars["Boolean"]["output"];
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
  prevPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type ListUserResponse = {
  hasNextPage: Scalars["Boolean"]["output"];
  hasPrevPage: Scalars["Boolean"]["output"];
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
  prevPageToken?: Maybe<Scalars["String"]["output"]>;
  users: Array<User>;
};

export type Mutation = {
  addFrozenWeek: Scalars["Boolean"]["output"];
  addFrozenWeekToUser: Scalars["Boolean"]["output"];
  addParticipantsToClassByEmail: ClassRoom;
  bookProgressCheck: Scalars["Boolean"]["output"];
  bookSpeakingClass: Scalars["Boolean"]["output"];
  createClassRoom: ClassRoom;
  createClassRoomPlan: UserClassRoomPlan;
  createFranchisee: Franchisee;
  createSeedUsers: Array<User>;
  createTopic: Topic;
  createUser: User;
  deleteClassRoom: Scalars["Boolean"]["output"];
  deleteClassRoomPlan: Scalars["Boolean"]["output"];
  deleteLessonFeedback: Scalars["Boolean"]["output"];
  deleteMe: Scalars["Boolean"]["output"];
  deleteTeacher: Scalars["Boolean"]["output"];
  editTopic: Topic;
  enrollUserToAllPaidContent: Scalars["Boolean"]["output"];
  giveProgressCheckFeedback: Scalars["Boolean"]["output"];
  leaveClassRoom: ClassRoomWithoutParticipantDto;
  leaveClassRoomReview: ClassRoomReviewDto;
  logicallyDeleteTopic: Scalars["Boolean"]["output"];
  removeAvailabilitySlot: Scalars["Boolean"]["output"];
  removeFrozenWeek: Scalars["Boolean"]["output"];
  removeFrozenWeekForUser: Scalars["Boolean"]["output"];
  removeParticipantFromClassRoom: RemoveParticipantFromClassRoomResponse;
  resolveLevelSuggestion: Scalars["Boolean"]["output"];
  seedUserClassRoomPlans: Array<UserClassRoomPlan>;
  sendResetPasswordEmail: Scalars["Boolean"]["output"];
  suspendTeacherClassRoomAssignmentUntil: Scalars["Boolean"]["output"];
  swapTeacher: Scalars["Boolean"]["output"];
  updateLastLogin: Scalars["Boolean"]["output"];
  updateMe: User;
  updateTeacher: Franchisee;
  updateUser: User;
  updateUserClassRoomPlan: UserClassRoomPlan;
  updateUserLevelToNext: User;
  upsertLessonFeedback: Scalars["Boolean"]["output"];
};

export type MutationAddFrozenWeekArgs = {
  week: FrozenWeekInput;
};

export type MutationAddFrozenWeekToUserArgs = {
  userId: Scalars["String"]["input"];
  week: FrozenWeekInput;
};

export type MutationAddParticipantsToClassByEmailArgs = {
  classRoomId: Scalars["String"]["input"];
  userEmails: Array<Scalars["String"]["input"]>;
};

export type MutationBookProgressCheckArgs = {
  bookSlotInput: BookSlotInput;
};

export type MutationBookSpeakingClassArgs = {
  bookSlotInput: BookSlotInput;
};

export type MutationCreateClassRoomArgs = {
  capacity: Scalars["Int"]["input"];
  classRoomType: ClassRoomType;
  participantIds: Array<Scalars["String"]["input"]>;
  speakingLevel: SpeakingClassLevel;
  startUtc: Scalars["String"]["input"];
  teacherId: Scalars["String"]["input"];
  topicId: Scalars["String"]["input"];
};

export type MutationCreateClassRoomPlanArgs = {
  endedAtWeekUtc: Scalars["String"]["input"];
  lessonsPerWeek: Scalars["Int"]["input"];
  planToRecoverId?: InputMaybe<Scalars["String"]["input"]>;
  startedAtWeekUtc: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
  warrantyConfig?: InputMaybe<UserClassRoomPlanWarrantyConfigInputDto>;
};

export type MutationCreateFranchiseeArgs = {
  email: Scalars["String"]["input"];
  familyName: Scalars["String"]["input"];
  givenName: Scalars["String"]["input"];
  phoneNumber: PhoneNumberInput;
};

export type MutationCreateSeedUsersArgs = {
  count: Scalars["Float"]["input"];
  speakingLevel: SpeakingLevel;
};

export type MutationCreateTopicArgs = {
  level: SpeakingClassLevel;
  nameIt: Scalars["String"]["input"];
  pdfUrl: Scalars["String"]["input"];
};

export type MutationCreateUserArgs = {
  activateAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  email: Scalars["String"]["input"];
  familyName?: InputMaybe<Scalars["String"]["input"]>;
  givenName?: InputMaybe<Scalars["String"]["input"]>;
  hideBookProgressCheckSection?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideBookSpeakingClassSection?: InputMaybe<Scalars["Boolean"]["input"]>;
  speakingLevel: SpeakingLevel;
};

export type MutationDeleteClassRoomArgs = {
  classRoomId: Scalars["String"]["input"];
};

export type MutationDeleteClassRoomPlanArgs = {
  planId: Scalars["String"]["input"];
};

export type MutationDeleteLessonFeedbackArgs = {
  lessonFeedbackId: Scalars["String"]["input"];
};

export type MutationDeleteTeacherArgs = {
  teacherId: Scalars["String"]["input"];
};

export type MutationEditTopicArgs = {
  id: Scalars["String"]["input"];
  nameIt?: InputMaybe<Scalars["String"]["input"]>;
  pdfUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationEnrollUserToAllPaidContentArgs = {
  userId: Scalars["String"]["input"];
};

export type MutationGiveProgressCheckFeedbackArgs = {
  input: Array<GiveProgressCheckFeedbackInput>;
};

export type MutationLeaveClassRoomArgs = {
  classRoomId: Scalars["String"]["input"];
};

export type MutationLeaveClassRoomReviewArgs = {
  classRoomId: Scalars["String"]["input"];
  generalLessonScore: Scalars["Int"]["input"];
  reviewNotes: Scalars["String"]["input"];
  teacherClearanceScore: Scalars["Int"]["input"];
  teacherComfortableScore: Scalars["Int"]["input"];
  teacherInvolvementScore: Scalars["Int"]["input"];
  topicDifficultyScore: Scalars["Int"]["input"];
  topicUsefulnessScore: Scalars["Int"]["input"];
};

export type MutationLogicallyDeleteTopicArgs = {
  id: Scalars["String"]["input"];
};

export type MutationRemoveAvailabilitySlotArgs = {
  slotId: Scalars["String"]["input"];
};

export type MutationRemoveFrozenWeekArgs = {
  week: FrozenWeekInput;
};

export type MutationRemoveFrozenWeekForUserArgs = {
  userId: Scalars["String"]["input"];
  week: FrozenWeekInput;
};

export type MutationRemoveParticipantFromClassRoomArgs = {
  classRoomId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type MutationResolveLevelSuggestionArgs = {
  suggestionId: Scalars["String"]["input"];
};

export type MutationSeedUserClassRoomPlansArgs = {
  endedAtWeekUtc: Scalars["String"]["input"];
  lessonsPerWeek: Scalars["Int"]["input"];
  startedAtWeekUtc: Scalars["String"]["input"];
  userIds: Array<Scalars["String"]["input"]>;
};

export type MutationSuspendTeacherClassRoomAssignmentUntilArgs = {
  suspendUntilUtc: Scalars["String"]["input"];
  teacherId: Scalars["String"]["input"];
};

export type MutationSwapTeacherArgs = {
  classRoomId: Scalars["String"]["input"];
  doNotMakeOldTeacherAvailableAgain: Scalars["Boolean"]["input"];
  teacherToAddId: Scalars["String"]["input"];
};

export type MutationUpdateMeArgs = {
  familyName?: InputMaybe<Scalars["String"]["input"]>;
  givenName?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateTeacherArgs = {
  familyName?: InputMaybe<Scalars["String"]["input"]>;
  givenName?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  phoneNumber?: InputMaybe<PhoneNumberInput>;
};

export type MutationUpdateUserArgs = {
  activateAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  familyName?: InputMaybe<Scalars["String"]["input"]>;
  givenName?: InputMaybe<Scalars["String"]["input"]>;
  hideBookProgressCheckSection?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideBookSpeakingClassSection?: InputMaybe<Scalars["Boolean"]["input"]>;
  speakingLevel?: InputMaybe<SpeakingLevel>;
  userId: Scalars["String"]["input"];
};

export type MutationUpdateUserClassRoomPlanArgs = {
  planId: Scalars["String"]["input"];
  planToRecoverId?: InputMaybe<Scalars["String"]["input"]>;
  warrantyConfig?: InputMaybe<UserClassRoomPlanWarrantyConfigInputDto>;
};

export type MutationUpdateUserLevelToNextArgs = {
  userId: Scalars["String"]["input"];
};

export type MutationUpsertLessonFeedbackArgs = {
  classRoomId: Scalars["String"]["input"];
  lessonFeedback: Array<UpsertUserLessonFeedbackInput>;
};

export type PhoneNumber = {
  countryCode: Scalars["String"]["output"];
  number: Scalars["String"]["output"];
};

export type PhoneNumberInput = {
  countryCode: Scalars["String"]["input"];
  number: Scalars["String"]["input"];
};

export enum ProgressCheckRecommendation {
  MoreAccuracy = "MoreAccuracy",
  MoreFluency = "MoreFluency",
  MoreImmersion = "MoreImmersion",
  MoreVocabulary = "MoreVocabulary",
}

export type ProgressCheckResultDto = {
  accuracy: Scalars["Int"]["output"];
  comprehension: Scalars["Int"]["output"];
  fluency: Scalars["Int"]["output"];
  noShow: Scalars["Boolean"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  passed: Scalars["Boolean"]["output"];
  progressCheckId: Scalars["String"]["output"];
  progressCheckLevel: SpeakingClassLevel;
  progressCheckStartedAtUtc: Scalars["String"]["output"];
  recommendation: Array<ProgressCheckRecommendation>;
  teacher: ClassRoomTeacher;
  teacherId: Scalars["String"]["output"];
  toLevel?: Maybe<SpeakingClassLevel>;
  userId: Scalars["String"]["output"];
  vocabularyRange: Scalars["Int"]["output"];
};

export type ProgressCheckTopic = {
  level: SpeakingClassLevel;
  pdfUri: Scalars["String"]["output"];
};

export type ProgressCheckUserDto = {
  alreadyGivenFeedback: Scalars["Boolean"]["output"];
  canImproveSpeakingLevel: Scalars["Boolean"]["output"];
  familyName: Scalars["String"]["output"];
  givenName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
};

export type Query = {
  adminGetClassRoomById: CompleteClassRoom;
  calculatePlanGivenSlotByParams: Scalars["Int"]["output"];
  getAllActiveUsersByIds: Array<Maybe<User>>;
  getAllByShiftBaseUserIds: Array<Franchisee>;
  getAllFranchisees: Array<Franchisee>;
  getAllTeacherByIds: Array<Franchisee>;
  getAllTopics: Array<Topic>;
  getAllUsersByIds: Array<Maybe<User>>;
  getAvailabilityByTeacherForWeek: Array<AvailabilitySlotDto>;
  getAvailabilityForProgressCheck: GetProgressCheckAvailabilityGraphqlResponse;
  getAvailabilityForSpeakingClass: GetSpeakingClassAvailabilityResponse;
  getAvailableTeachersInHour: Array<ClassRoomTeacher>;
  getByAuthId: User;
  getClassRoomById: ClassRoom;
  getClassRoomByIdV2: ClassRoomV2;
  getClassRoomPlanByIdAndUserId: UserClassRoomPlan;
  getClassRoomsByTeacherAndDateRange: Array<ClassRoomV2>;
  getCurrentLoggedInTeacherAvailability: Array<TeacherAvailabilitySlot>;
  getFirstPassedProgressCheckResultByUserIds: Array<ProgressCheckResultDto>;
  getFranchiseeById: Franchisee;
  getFreezableWeeks: GetFreezableWeeksResponse;
  getFreezableWeeksByUser: GetFreezableWeeksResponse;
  getFrozenWeeksByUser: Array<FrozenWeekDto>;
  getIdsOfUsersEligibleForRLCWarranty: Array<Scalars["String"]["output"]>;
  getLessonFeedbackReportByUser: UserLessonFeedbackReport;
  getLoggedTeacher: Franchisee;
  getMinUsedTopicsInLevelAmongParticipants: Array<Topic>;
  getMyFrozenWeeks: Array<FrozenWeekDto>;
  getMyRemainingLessons: GetMyRemainingLessonResponse;
  getProgressCheckResultByUserId: Array<ProgressCheckResultDto>;
  getProgressCheckUserList: GetProgressCheckUserListResponse;
  getTeacherByAuthId: Franchisee;
  getTeacherClassRooms: Array<ClassRoomWithoutParticipantDto>;
  getTeacherFutureClassRooms: Array<ClassRoomV2>;
  getTopicById: Topic;
  getUserByEmailEvenIfDeleted: User;
  getUserById: User;
  getUserClassRoomCount: GetUserClassRoomCountResponse;
  getUserClassRoomPlans: Array<UserClassRoomPlan>;
  getUserClassRoomsBetweenDates: Array<ClassRoomWithoutParticipantDto>;
  getUserRemainingLessonByWeek: GetUserRemainingLessonCountByWeekResponse;
  getUserRemainingLessons: Scalars["Int"]["output"];
  getUsersEligibleForWarranty: Array<User>;
  hasSpeakingEnabled: Scalars["Boolean"]["output"];
  hasUnlockedProgressCheck: HasUnlockedProgressCheckResponse;
  isActiveUser: Scalars["Boolean"]["output"];
  isActiveUserForAdminById: Scalars["Boolean"]["output"];
  isFrozenWeek: Scalars["Boolean"]["output"];
  listAllUsers: ListUserResponse;
  listClassRooms: ListClassRoomResponse;
  listNotResolvedLevelChangeSuggestions: Array<LevelChangeSuggestionDto>;
  me?: Maybe<User>;
  safelyGetUserByEmail?: Maybe<User>;
  searchTeachersByEmail: Array<Franchisee>;
  speakingClassServiceAgGridQuery: AgGridQueryResponse;
};

export type QueryAdminGetClassRoomByIdArgs = {
  classRoomId: Scalars["String"]["input"];
};

export type QueryCalculatePlanGivenSlotByParamsArgs = {
  endedAtWeekUtc: Scalars["String"]["input"];
  lessonsPerWeek: Scalars["Int"]["input"];
  startedAtWeekUtc: Scalars["String"]["input"];
};

export type QueryGetAllActiveUsersByIdsArgs = {
  userIds: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type QueryGetAllByShiftBaseUserIdsArgs = {
  shiftBaseUserIds: Array<Scalars["String"]["input"]>;
};

export type QueryGetAllTeacherByIdsArgs = {
  ids: Array<Scalars["String"]["input"]>;
};

export type QueryGetAllUsersByIdsArgs = {
  userIds: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type QueryGetAvailabilityByTeacherForWeekArgs = {
  fromDate: Scalars["String"]["input"];
  teacherId: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type QueryGetAvailabilityForProgressCheckArgs = {
  clientTimezone: Scalars["String"]["input"];
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type QueryGetAvailabilityForSpeakingClassArgs = {
  clientTimezone: Scalars["String"]["input"];
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type QueryGetAvailableTeachersInHourArgs = {
  startUtc: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetByAuthIdArgs = {
  authId: Scalars["String"]["input"];
};

export type QueryGetClassRoomByIdArgs = {
  classRoomId: Scalars["String"]["input"];
};

export type QueryGetClassRoomByIdV2Args = {
  classRoomId: Scalars["String"]["input"];
};

export type QueryGetClassRoomPlanByIdAndUserIdArgs = {
  planId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type QueryGetClassRoomsByTeacherAndDateRangeArgs = {
  endUtc: Scalars["String"]["input"];
  startUtc: Scalars["String"]["input"];
};

export type QueryGetCurrentLoggedInTeacherAvailabilityArgs = {
  maxDateString: Scalars["String"]["input"];
  minDateString: Scalars["String"]["input"];
};

export type QueryGetFirstPassedProgressCheckResultByUserIdsArgs = {
  userIds: Array<Scalars["String"]["input"]>;
};

export type QueryGetFranchiseeByIdArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetFreezableWeeksByUserArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetFrozenWeeksByUserArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetIdsOfUsersEligibleForRlcWarrantyArgs = {
  plansEndAfter?: InputMaybe<Scalars["String"]["input"]>;
  plansEndBefore?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetLessonFeedbackReportByUserArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetMinUsedTopicsInLevelAmongParticipantsArgs = {
  participantIds: Array<Scalars["String"]["input"]>;
  speakingLevel: SpeakingClassLevel;
};

export type QueryGetProgressCheckResultByUserIdArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetProgressCheckUserListArgs = {
  progressCheckId: Scalars["String"]["input"];
};

export type QueryGetTeacherByAuthIdArgs = {
  authId: Scalars["String"]["input"];
};

export type QueryGetTeacherClassRoomsArgs = {
  fromDate: Scalars["String"]["input"];
  teacherId: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type QueryGetTeacherFutureClassRoomsArgs = {
  teacherId: Scalars["String"]["input"];
};

export type QueryGetTopicByIdArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetUserByEmailEvenIfDeletedArgs = {
  email: Scalars["String"]["input"];
};

export type QueryGetUserByIdArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetUserClassRoomPlansArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetUserClassRoomsBetweenDatesArgs = {
  clientTimezone: Scalars["String"]["input"];
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
};

export type QueryGetUserRemainingLessonByWeekArgs = {
  clientTimezone: Scalars["String"]["input"];
  dateUtc: Scalars["String"]["input"];
};

export type QueryGetUserRemainingLessonsArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetUsersEligibleForWarrantyArgs = {
  plansEndAfter?: InputMaybe<Scalars["String"]["input"]>;
  plansEndBefore?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryIsActiveUserForAdminByIdArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryIsFrozenWeekArgs = {
  week: FrozenWeekInput;
};

export type QueryListAllUsersArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  prevPageToken?: InputMaybe<Scalars["String"]["input"]>;
  withSpeakingLevel?: InputMaybe<Array<InputMaybe<SpeakingLevel>>>;
};

export type QueryListClassRoomsArgs = {
  dateRange?: InputMaybe<ListClassRoomDateInput>;
  limit: Scalars["Int"]["input"];
  nextToken?: InputMaybe<Scalars["String"]["input"]>;
  prevToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySafelyGetUserByEmailArgs = {
  email: Scalars["String"]["input"];
};

export type QuerySearchTeachersByEmailArgs = {
  filter: Scalars["String"]["input"];
};

export type QuerySpeakingClassServiceAgGridQueryArgs = {
  params: AgGridParamsInput;
};

export type RemainingWeeksToFreeze = {
  planId: Scalars["String"]["output"];
  remainingCount: Scalars["Float"]["output"];
};

export type RemoveParticipantFromClassRoomResponse = {
  classRoom: ClassRoom;
  classRoomDeleted: Scalars["Boolean"]["output"];
};

export enum SpeakingClassLevel {
  Explorers = "Explorers",
  Navigators = "Navigators",
  Pioneers = "Pioneers",
  Starters = "Starters",
  Voyagers = "Voyagers",
}

/** The user speaking level */
export enum SpeakingLevel {
  Explorers = "Explorers",
  Navigators = "Navigators",
  Pioneers = "Pioneers",
  Starters = "Starters",
  Unknown = "Unknown",
  Voyagers = "Voyagers",
}

export type TeacherAvailabilitySlot = {
  endDateTime: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  startDateTime: Scalars["String"]["output"];
};

export type Topic = {
  id: Scalars["String"]["output"];
  level: SpeakingClassLevel;
  name: Scalars["String"]["output"];
  pdfUrl: Scalars["String"]["output"];
};

export type UpsertUserLessonFeedbackInput = {
  differentSpeakingLevelSuggested?: InputMaybe<SpeakingClassLevel>;
  noShow: Scalars["Boolean"]["input"];
  notes: Scalars["String"]["input"];
  participationScore: Scalars["Int"]["input"];
  targetLanguageScore: Scalars["Int"]["input"];
  userId: Scalars["String"]["input"];
};

export type User = {
  authId: Scalars["String"]["output"];
  createdAt: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  familyName: Scalars["String"]["output"];
  givenName: Scalars["String"]["output"];
  hideBookProgressCheckSection: Scalars["Boolean"]["output"];
  hideBookSpeakingClassSection: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  lessonFeedbacks: Array<UserLessonFeedbackDto>;
  partecipatedProgressCheckCount: Scalars["Int"]["output"];
  partecipatedSpeakingClassCount: Scalars["Int"]["output"];
  progressCheckResults: Array<ProgressCheckResultDto>;
  speakingLevel: SpeakingLevel;
  startingSpeakingLevel: SpeakingLevel;
};

export type UserClassRoomPlan = {
  endedAtWeekUtc: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  lessonsPerWeek: Scalars["Float"]["output"];
  numberOfSlotIncluded: Scalars["Float"]["output"];
  planToRecoverId?: Maybe<Scalars["String"]["output"]>;
  startedAtWeekUtc: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
  warrantyConfig?: Maybe<UserClassRoomPlanWarrantyConfigDto>;
};

export type UserClassRoomPlanWarrantyConfigDto = {
  enabled: Scalars["Boolean"]["output"];
  minAttendedLessons?: Maybe<Scalars["Int"]["output"]>;
};

export type UserClassRoomPlanWarrantyConfigInputDto = {
  enabled: Scalars["Boolean"]["input"];
  minAttendedLessons?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserLessonFeedbackDto = {
  classRoomEndDateUtc: Scalars["String"]["output"];
  classRoomStartDateUtc: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  noShow: Scalars["Boolean"]["output"];
  notes: Scalars["String"]["output"];
  participation: Scalars["Int"]["output"];
  targetLanguage: Scalars["Int"]["output"];
  teacher: ClassRoomTeacher;
  teacherId: Scalars["String"]["output"];
};

export type UserLessonFeedbackNote = {
  classRoomStartDateUtc: Scalars["String"]["output"];
  classRoomTopicName: Scalars["String"]["output"];
  note: Scalars["String"]["output"];
};

export type UserLessonFeedbackReport = {
  avgParticipationScore: Scalars["Float"]["output"];
  avgTargetLanguageScore: Scalars["Float"]["output"];
  hasAtLeastOneFeedback: Scalars["Boolean"]["output"];
  notes: Array<UserLessonFeedbackNote>;
  userFamilyName: Scalars["String"]["output"];
  userGivenName: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
};

export type WeekWithPlan = {
  planId: Scalars["String"]["output"];
  week: FrozenWeekDto;
};

export type SpeakingClassServiceAgGridQueryQueryVariables = Exact<{
  params: AgGridParamsInput;
}>;

export type SpeakingClassServiceAgGridQueryQuery = {
  speakingClassServiceAgGridQuery: { hasNext: boolean; data: any };
};

export type ClassRoomListItemFragment = {
  id: string;
  startedAtUtc: string;
  endedAtUtc: string;
  type: ClassRoomType;
  speakingLevel: SpeakingClassLevel;
  participantsCount: number;
  capacity: number;
  completedFeedbacksCount: number;
  topic: { id: string; name: string };
  teacher: {
    id: string;
    givenName: string;
    familyName: string;
    email: string;
    phone?: { countryCode: string; number: string } | null;
  };
} & { " $fragmentName"?: "ClassRoomListItemFragment" };

export type ClassRoomWithParticipantsFragment = {
  id: string;
  speakingLevel: SpeakingClassLevel;
  startedAtUtc: string;
  endedAtUtc: string;
  capacity: number;
  topic: { name: string };
  participants: Array<{ id: string; givenName: string; familyName: string }>;
} & { " $fragmentName"?: "ClassRoomWithParticipantsFragment" };

export type ClassRoomWithoutParticipantFragment = {
  id: string;
  startedAtUtc: string;
  endedAtUtc: string;
  speakingLevel: SpeakingClassLevel;
  participantsCount: number;
  classRoomTimezone: string;
  type: ClassRoomType;
  topic: { name: string };
} & { " $fragmentName"?: "ClassRoomWithoutParticipantFragment" };

export type CompleteClassRoomFragment = {
  id: string;
  speakingLevel: SpeakingClassLevel;
  startedAtUtc: string;
  endedAtUtc: string;
  capacity: number;
  conferenceUri: string;
  feedbackUrl: string;
  feedbackReportUrl: string;
  topic: { name: string };
  participants: Array<{
    id: string;
    givenName: string;
    familyName: string;
    email: string;
  }>;
  teacher: { email: string; id: string; familyName: string; givenName: string };
} & { " $fragmentName"?: "CompleteClassRoomFragment" };

export type GetMinUsedTopicInLevelAmongParticipantsQueryVariables = Exact<{
  participantIds:
    | Array<Scalars["String"]["input"]>
    | Scalars["String"]["input"];
  speakingLevel: SpeakingClassLevel;
}>;

export type GetMinUsedTopicInLevelAmongParticipantsQuery = {
  getMinUsedTopicsInLevelAmongParticipants: Array<{
    " $fragmentRefs"?: { TopicItemFragment: TopicItemFragment };
  }>;
};

export type CreateClassRoomMutationVariables = Exact<{
  capacity: Scalars["Int"]["input"];
  classRoomType: ClassRoomType;
  participantIds:
    | Array<Scalars["String"]["input"]>
    | Scalars["String"]["input"];
  speakingLevel: SpeakingClassLevel;
  startUtc: Scalars["String"]["input"];
  teacherId: Scalars["String"]["input"];
  topicId: Scalars["String"]["input"];
}>;

export type CreateClassRoomMutation = { createClassRoom: { id: string } };

export type AddParticipantsToClassByEmailMutationVariables = Exact<{
  classRoomId: Scalars["String"]["input"];
  emails: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type AddParticipantsToClassByEmailMutation = {
  addParticipantsToClassByEmail: {
    " $fragmentRefs"?: {
      ClassRoomWithParticipantsFragment: ClassRoomWithParticipantsFragment;
    };
  };
};

export type AdminGetClassRoomByIdQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type AdminGetClassRoomByIdQuery = {
  adminGetClassRoomById: {
    " $fragmentRefs"?: { CompleteClassRoomFragment: CompleteClassRoomFragment };
  };
};

export type ListClassRoomsQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  nextToken?: InputMaybe<Scalars["String"]["input"]>;
  prevToken?: InputMaybe<Scalars["String"]["input"]>;
  dateRange?: InputMaybe<ListClassRoomDateInput>;
}>;

export type ListClassRoomsQuery = {
  listClassRooms: {
    hasNextPage: boolean;
    hasPrevPage: boolean;
    nextPageToken?: string | null;
    prevPageToken?: string | null;
    classRooms: Array<{
      " $fragmentRefs"?: {
        ClassRoomListItemFragment: ClassRoomListItemFragment;
      };
    }>;
  };
};

export type DeleteClassRoomMutationVariables = Exact<{
  classRoomId: Scalars["String"]["input"];
}>;

export type DeleteClassRoomMutation = { deleteClassRoom: boolean };

export type RemoveParticipantFromClassRoomMutationVariables = Exact<{
  classRoomId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
}>;

export type RemoveParticipantFromClassRoomMutation = {
  removeParticipantFromClassRoom: {
    classRoomDeleted: boolean;
    classRoom: {
      " $fragmentRefs"?: {
        ClassRoomWithParticipantsFragment: ClassRoomWithParticipantsFragment;
      };
    };
  };
};

export type SwapTeacherMutationVariables = Exact<{
  classRoomId: Scalars["String"]["input"];
  teacherId: Scalars["String"]["input"];
  doNotMakeOldTeacherAvailableAgain: Scalars["Boolean"]["input"];
}>;

export type SwapTeacherMutation = { swapTeacher: boolean };

export type FranchiseeItemFragment = {
  id: string;
  email: string;
  familyName: string;
  givenName: string;
  phoneNumber?: { countryCode: string; number: string } | null;
} & { " $fragmentName"?: "FranchiseeItemFragment" };

export type ClassRoomTeacherFragmentFragment = {
  id: string;
  email: string;
  givenName: string;
  familyName: string;
  phone?: { countryCode: string; number: string } | null;
} & { " $fragmentName"?: "ClassRoomTeacherFragmentFragment" };

export type AvailabilitySlotFragmentFragment = {
  slotId: string;
  startDateUtc: string;
  endDateUtc: string;
  slotTimezone: string;
} & { " $fragmentName"?: "AvailabilitySlotFragmentFragment" };

export type RemoveAvailabilitySlotMutationVariables = Exact<{
  slotId: Scalars["String"]["input"];
}>;

export type RemoveAvailabilitySlotMutation = {
  removeAvailabilitySlot: boolean;
};

export type GetAvailabilityByTeacherForWeekQueryVariables = Exact<{
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
  teacherId: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type GetAvailabilityByTeacherForWeekQuery = {
  getAvailabilityByTeacherForWeek: Array<{
    " $fragmentRefs"?: {
      AvailabilitySlotFragmentFragment: AvailabilitySlotFragmentFragment;
    };
  }>;
};

export type GetTeacherClassRoomsQueryVariables = Exact<{
  timezone: Scalars["String"]["input"];
  teacherId: Scalars["String"]["input"];
  fromDate: Scalars["String"]["input"];
  toDate: Scalars["String"]["input"];
}>;

export type GetTeacherClassRoomsQuery = {
  getTeacherClassRooms: Array<{
    " $fragmentRefs"?: {
      ClassRoomWithoutParticipantFragment: ClassRoomWithoutParticipantFragment;
    };
  }>;
};

export type GetAllFranchiseesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllFranchiseesQuery = {
  getAllFranchisees: Array<{
    " $fragmentRefs"?: { FranchiseeItemFragment: FranchiseeItemFragment };
  }>;
};

export type GetAvailableTeachersInHourQueryVariables = Exact<{
  startUtc: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type GetAvailableTeachersInHourQuery = {
  getAvailableTeachersInHour: Array<{
    " $fragmentRefs"?: {
      ClassRoomTeacherFragmentFragment: ClassRoomTeacherFragmentFragment;
    };
  }>;
};

export type CreateFranchiseeMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  familyName: Scalars["String"]["input"];
  givenName: Scalars["String"]["input"];
  phoneNumber: PhoneNumberInput;
}>;

export type CreateFranchiseeMutation = {
  createFranchisee: {
    " $fragmentRefs"?: { FranchiseeItemFragment: FranchiseeItemFragment };
  };
};

export type DeleteTeacherMutationVariables = Exact<{
  teacherId: Scalars["String"]["input"];
}>;

export type DeleteTeacherMutation = { deleteTeacher: boolean };

export type GetFranchiseeByIdQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetFranchiseeByIdQuery = {
  getFranchiseeById: {
    " $fragmentRefs"?: { FranchiseeItemFragment: FranchiseeItemFragment };
  };
};

export type TopicItemFragment = {
  id: string;
  name: string;
  level: SpeakingClassLevel;
  pdfUrl: string;
} & { " $fragmentName"?: "TopicItemFragment" };

export type GetAllTopicsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllTopicsQuery = {
  getAllTopics: Array<{
    " $fragmentRefs"?: { TopicItemFragment: TopicItemFragment };
  }>;
};

export type CreateTopicMutationVariables = Exact<{
  nameIt: Scalars["String"]["input"];
  level: SpeakingClassLevel;
  pdfUrl: Scalars["String"]["input"];
}>;

export type CreateTopicMutation = {
  createTopic: { id: string; name: string; level: SpeakingClassLevel };
};

export type EditTopicMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  nameIt?: InputMaybe<Scalars["String"]["input"]>;
  pdfUrl?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type EditTopicMutation = {
  editTopic: { " $fragmentRefs"?: { TopicItemFragment: TopicItemFragment } };
};

export type LogicallyDeleteTopicMutationVariables = Exact<{
  topicId: Scalars["String"]["input"];
}>;

export type LogicallyDeleteTopicMutation = { logicallyDeleteTopic: boolean };

export type GetTopicByIdQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetTopicByIdQuery = {
  getTopicById: { " $fragmentRefs"?: { TopicItemFragment: TopicItemFragment } };
};

export type ClassRoomPlanFragmentFragment = {
  id: string;
  userId: string;
  startedAtWeekUtc: string;
  endedAtWeekUtc: string;
  lessonsPerWeek: number;
  numberOfSlotIncluded: number;
  planToRecoverId?: string | null;
  warrantyConfig?: {
    enabled: boolean;
    minAttendedLessons?: number | null;
  } | null;
} & { " $fragmentName"?: "ClassRoomPlanFragmentFragment" };

export type FrozenWeekFragment = {
  startOfWeekUtc: string;
  endOfWeekUtc: string;
} & { " $fragmentName"?: "FrozenWeekFragment" };

export type GetFreezableWeeksResponseFragment = {
  weeks: Array<{
    planId: string;
    week: { startOfWeekUtc: string; endOfWeekUtc: string };
  }>;
  remainingWeeksToFreeze: Array<{ remainingCount: number; planId: string }>;
} & { " $fragmentName"?: "GetFreezableWeeksResponseFragment" };

export type AddFrozenWeekToUserMutationVariables = Exact<{
  week: FrozenWeekInput;
  userId: Scalars["String"]["input"];
}>;

export type AddFrozenWeekToUserMutation = { addFrozenWeekToUser: boolean };

export type RemoveFrozenWeekForUserMutationVariables = Exact<{
  week: FrozenWeekInput;
  userId: Scalars["String"]["input"];
}>;

export type RemoveFrozenWeekForUserMutation = {
  removeFrozenWeekForUser: boolean;
};

export type GetFreezableWeeksByUserQueryVariables = Exact<{
  userId: Scalars["String"]["input"];
}>;

export type GetFreezableWeeksByUserQuery = {
  getFreezableWeeksByUser: {
    " $fragmentRefs"?: {
      GetFreezableWeeksResponseFragment: GetFreezableWeeksResponseFragment;
    };
  };
};

export type GetFrozenWeeksByUserQueryVariables = Exact<{
  userId: Scalars["String"]["input"];
}>;

export type GetFrozenWeeksByUserQuery = {
  getFrozenWeeksByUser: Array<{
    " $fragmentRefs"?: { FrozenWeekFragment: FrozenWeekFragment };
  }>;
};

export type CalculatePlanGivenSlotByParamsQueryVariables = Exact<{
  endedAtWeekUtc: Scalars["String"]["input"];
  lessonsPerWeek: Scalars["Int"]["input"];
  startedAtWeekUtc: Scalars["String"]["input"];
}>;

export type CalculatePlanGivenSlotByParamsQuery = {
  calculatePlanGivenSlotByParams: number;
};

export type CreateClassRoomPlanMutationVariables = Exact<{
  userId: Scalars["String"]["input"];
  startedAtWeekUtc: Scalars["String"]["input"];
  lessonsPerWeek: Scalars["Int"]["input"];
  endedAtWeekUtc: Scalars["String"]["input"];
  planToRecoverId?: InputMaybe<Scalars["String"]["input"]>;
  warrantyConfig?: InputMaybe<UserClassRoomPlanWarrantyConfigInputDto>;
}>;

export type CreateClassRoomPlanMutation = {
  createClassRoomPlan: {
    " $fragmentRefs"?: {
      ClassRoomPlanFragmentFragment: ClassRoomPlanFragmentFragment;
    };
  };
};

export type DeleteClassRoomPlanMutationVariables = Exact<{
  planId: Scalars["String"]["input"];
}>;

export type DeleteClassRoomPlanMutation = { deleteClassRoomPlan: boolean };

export type GetClassRoomPlanByIdAndUserIdQueryVariables = Exact<{
  planId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
}>;

export type GetClassRoomPlanByIdAndUserIdQuery = {
  getClassRoomPlanByIdAndUserId: {
    " $fragmentRefs"?: {
      ClassRoomPlanFragmentFragment: ClassRoomPlanFragmentFragment;
    };
  };
};

export type SetUserClassRoomPlanAsRecoveryOfMutationVariables = Exact<{
  planToRecoverId?: InputMaybe<Scalars["String"]["input"]>;
  planId: Scalars["String"]["input"];
  warrantyConfig?: InputMaybe<UserClassRoomPlanWarrantyConfigInputDto>;
}>;

export type SetUserClassRoomPlanAsRecoveryOfMutation = {
  updateUserClassRoomPlan: {
    " $fragmentRefs"?: {
      ClassRoomPlanFragmentFragment: ClassRoomPlanFragmentFragment;
    };
  };
};

export type GetUserClassRoomPlansQueryVariables = Exact<{
  userId: Scalars["String"]["input"];
}>;

export type GetUserClassRoomPlansQuery = {
  getUserClassRoomPlans: Array<{
    " $fragmentRefs"?: {
      ClassRoomPlanFragmentFragment: ClassRoomPlanFragmentFragment;
    };
  }>;
};

export type GetUserRemainingLessonsQueryVariables = Exact<{
  userId: Scalars["String"]["input"];
}>;

export type GetUserRemainingLessonsQuery = { getUserRemainingLessons: number };

export type LessonFeedbackReportFragmentFragment = {
  avgParticipationScore: number;
  avgTargetLanguageScore: number;
  hasAtLeastOneFeedback: boolean;
  notes: Array<{
    classRoomStartDateUtc: string;
    classRoomTopicName: string;
    note: string;
  }>;
} & { " $fragmentName"?: "LessonFeedbackReportFragmentFragment" };

export type DeleteLessonFeedbackMutationVariables = Exact<{
  lessonFeedbackId: Scalars["String"]["input"];
}>;

export type DeleteLessonFeedbackMutation = { deleteLessonFeedback: boolean };

export type GetLessonFeedbackReportByUserQueryVariables = Exact<{
  userId: Scalars["String"]["input"];
}>;

export type GetLessonFeedbackReportByUserQuery = {
  getLessonFeedbackReportByUser: {
    " $fragmentRefs"?: {
      LessonFeedbackReportFragmentFragment: LessonFeedbackReportFragmentFragment;
    };
  };
};

export type ProgressCheckResultFragment = {
  comprehension: number;
  accuracy: number;
  vocabularyRange: number;
  fluency: number;
  notes?: string | null;
  recommendation: Array<ProgressCheckRecommendation>;
  userId: string;
  progressCheckLevel: SpeakingClassLevel;
  progressCheckStartedAtUtc: string;
  passed: boolean;
} & { " $fragmentName"?: "ProgressCheckResultFragment" };

export type LevelChangeSuggestionFragment = {
  id: string;
  suggestedLevels: Array<SpeakingClassLevel>;
  user: { " $fragmentRefs"?: { UserFragmentFragment: UserFragmentFragment } };
} & { " $fragmentName"?: "LevelChangeSuggestionFragment" };

export type ListNotResolvedLevelChangeSuggestionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListNotResolvedLevelChangeSuggestionsQuery = {
  listNotResolvedLevelChangeSuggestions: Array<{
    " $fragmentRefs"?: {
      LevelChangeSuggestionFragment: LevelChangeSuggestionFragment;
    };
  }>;
};

export type ResolveLevelSuggestionMutationVariables = Exact<{
  suggestionId: Scalars["String"]["input"];
}>;

export type ResolveLevelSuggestionMutation = {
  resolveLevelSuggestion: boolean;
};

export type UserFragmentFragment = {
  id: string;
  email: string;
  speakingLevel: SpeakingLevel;
  givenName: string;
  familyName: string;
  authId: string;
  partecipatedProgressCheckCount: number;
  partecipatedSpeakingClassCount: number;
  hideBookProgressCheckSection: boolean;
  hideBookSpeakingClassSection: boolean;
  lessonFeedbacks: Array<{
    id: string;
    noShow: boolean;
    participation: number;
    targetLanguage: number;
    notes: string;
    classRoomEndDateUtc: string;
    classRoomStartDateUtc: string;
    teacher: {
      id: string;
      givenName: string;
      familyName: string;
      email: string;
    };
  }>;
  progressCheckResults: Array<{
    comprehension: number;
    accuracy: number;
    vocabularyRange: number;
    fluency: number;
    notes?: string | null;
    recommendation: Array<ProgressCheckRecommendation>;
    userId: string;
    progressCheckLevel: SpeakingClassLevel;
    progressCheckStartedAtUtc: string;
    passed: boolean;
    teacher: {
      givenName: string;
      familyName: string;
      email: string;
      id: string;
    };
  }>;
} & { " $fragmentName"?: "UserFragmentFragment" };

export type GetUsersEligibleForWarrantyQueryVariables = Exact<{
  plansEndBefore?: InputMaybe<Scalars["String"]["input"]>;
  plansEndAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetUsersEligibleForWarrantyQuery = {
  getUsersEligibleForWarranty: Array<{
    " $fragmentRefs"?: { UserFragmentFragment: UserFragmentFragment };
  }>;
};

export type ListAllUsersQueryVariables = Exact<{
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  prevPageToken?: InputMaybe<Scalars["String"]["input"]>;
  withSpeakingLevel?: InputMaybe<
    Array<InputMaybe<SpeakingLevel>> | InputMaybe<SpeakingLevel>
  >;
}>;

export type ListAllUsersQuery = {
  listAllUsers: {
    hasNextPage: boolean;
    nextPageToken?: string | null;
    hasPrevPage: boolean;
    prevPageToken?: string | null;
    users: Array<{
      " $fragmentRefs"?: { UserFragmentFragment: UserFragmentFragment };
    }>;
  };
};

export type CreateUserMutationVariables = Exact<{
  speakingLevel: SpeakingLevel;
  email: Scalars["String"]["input"];
  givenName: Scalars["String"]["input"];
  familyName: Scalars["String"]["input"];
  activateAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideBookSpeakingClassSection?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideBookProgressCheckSection?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type CreateUserMutation = {
  createUser: {
    " $fragmentRefs"?: { UserFragmentFragment: UserFragmentFragment };
  };
};

export type EnrollUserToAllPaidContentMutationVariables = Exact<{
  userId: Scalars["String"]["input"];
}>;

export type EnrollUserToAllPaidContentMutation = {
  enrollUserToAllPaidContent: boolean;
};

export type IsActiveUserForAdminByIdQueryVariables = Exact<{
  userId: Scalars["String"]["input"];
}>;

export type IsActiveUserForAdminByIdQuery = {
  isActiveUserForAdminById: boolean;
};

export type UpdateUserMutationVariables = Exact<{
  familyName?: InputMaybe<Scalars["String"]["input"]>;
  givenName?: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["String"]["input"];
  speakingLevel?: InputMaybe<SpeakingLevel>;
  activateAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideBookSpeakingClassSection?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideBookProgressCheckSection?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateUserMutation = {
  updateUser: {
    " $fragmentRefs"?: { UserFragmentFragment: UserFragmentFragment };
  };
};

export type GetUserByIdQueryVariables = Exact<{
  userId: Scalars["String"]["input"];
}>;

export type GetUserByIdQuery = {
  getUserById: {
    " $fragmentRefs"?: { UserFragmentFragment: UserFragmentFragment };
  };
};

export const ClassRoomListItemFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomListItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ClassRoomListItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "participantsCount" } },
          { kind: "Field", name: { kind: "Name", value: "capacity" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "topic" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "teacher" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "givenName" } },
                { kind: "Field", name: { kind: "Name", value: "familyName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "phone" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "number" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completedFeedbacksCount" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClassRoomListItemFragment, unknown>;
export const ClassRoomWithParticipantsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomWithParticipants" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ClassRoom" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endedAtUtc" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "topic" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participants" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "givenName" } },
                { kind: "Field", name: { kind: "Name", value: "familyName" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "capacity" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClassRoomWithParticipantsFragment, unknown>;
export const ClassRoomWithoutParticipantFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomWithoutParticipant" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ClassRoomWithoutParticipantDto" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "topic" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "participantsCount" } },
          { kind: "Field", name: { kind: "Name", value: "classRoomTimezone" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClassRoomWithoutParticipantFragment, unknown>;
export const CompleteClassRoomFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompleteClassRoom" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CompleteClassRoom" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endedAtUtc" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "topic" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participants" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "givenName" } },
                { kind: "Field", name: { kind: "Name", value: "familyName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "capacity" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "teacher" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "familyName" } },
                { kind: "Field", name: { kind: "Name", value: "givenName" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "conferenceUri" } },
          { kind: "Field", name: { kind: "Name", value: "feedbackUrl" } },
          { kind: "Field", name: { kind: "Name", value: "feedbackReportUrl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompleteClassRoomFragment, unknown>;
export const FranchiseeItemFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FranchiseeItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Franchisee" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "phoneNumber" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "number" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FranchiseeItemFragment, unknown>;
export const ClassRoomTeacherFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomTeacherFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ClassRoomTeacher" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "phone" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "number" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClassRoomTeacherFragmentFragment, unknown>;
export const AvailabilitySlotFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AvailabilitySlotFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AvailabilitySlotDto" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "slotId" } },
          { kind: "Field", name: { kind: "Name", value: "startDateUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endDateUtc" } },
          { kind: "Field", name: { kind: "Name", value: "slotTimezone" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AvailabilitySlotFragmentFragment, unknown>;
export const TopicItemFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TopicItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Topic" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "level" } },
          { kind: "Field", name: { kind: "Name", value: "pdfUrl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopicItemFragment, unknown>;
export const ClassRoomPlanFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomPlanFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "UserClassRoomPlan" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtWeekUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endedAtWeekUtc" } },
          { kind: "Field", name: { kind: "Name", value: "lessonsPerWeek" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "numberOfSlotIncluded" },
          },
          { kind: "Field", name: { kind: "Name", value: "planToRecoverId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "warrantyConfig" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "enabled" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "minAttendedLessons" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClassRoomPlanFragmentFragment, unknown>;
export const FrozenWeekFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FrozenWeek" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FrozenWeekDto" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "startOfWeekUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endOfWeekUtc" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FrozenWeekFragment, unknown>;
export const GetFreezableWeeksResponseFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GetFreezableWeeksResponse" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "GetFreezableWeeksResponse" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "weeks" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "planId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "week" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startOfWeekUtc" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endOfWeekUtc" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "remainingWeeksToFreeze" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "remainingCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "planId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFreezableWeeksResponseFragment, unknown>;
export const LessonFeedbackReportFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LessonFeedbackReportFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "UserLessonFeedbackReport" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "avgParticipationScore" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avgTargetLanguageScore" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "notes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomStartDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomTopicName" },
                },
                { kind: "Field", name: { kind: "Name", value: "note" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hasAtLeastOneFeedback" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LessonFeedbackReportFragmentFragment, unknown>;
export const ProgressCheckResultFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProgressCheckResult" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ProgressCheckResultDto" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "comprehension" } },
          { kind: "Field", name: { kind: "Name", value: "accuracy" } },
          { kind: "Field", name: { kind: "Name", value: "vocabularyRange" } },
          { kind: "Field", name: { kind: "Name", value: "fluency" } },
          { kind: "Field", name: { kind: "Name", value: "notes" } },
          { kind: "Field", name: { kind: "Name", value: "recommendation" } },
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "progressCheckLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "progressCheckStartedAtUtc" },
          },
          { kind: "Field", name: { kind: "Name", value: "passed" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProgressCheckResultFragment, unknown>;
export const UserFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          { kind: "Field", name: { kind: "Name", value: "authId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "partecipatedProgressCheckCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "partecipatedSpeakingClassCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lessonFeedbacks" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "noShow" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participation" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "targetLanguage" },
                },
                { kind: "Field", name: { kind: "Name", value: "noShow" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomEndDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomStartDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teacher" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "givenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "familyName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "progressCheckResults" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teacher" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "givenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "familyName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "comprehension" },
                },
                { kind: "Field", name: { kind: "Name", value: "accuracy" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "vocabularyRange" },
                },
                { kind: "Field", name: { kind: "Name", value: "fluency" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recommendation" },
                },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheckLevel" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheckStartedAtUtc" },
                },
                { kind: "Field", name: { kind: "Name", value: "passed" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideBookProgressCheckSection" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideBookSpeakingClassSection" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFragmentFragment, unknown>;
export const LevelChangeSuggestionFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LevelChangeSuggestion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "LevelChangeSuggestionDto" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "suggestedLevels" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          { kind: "Field", name: { kind: "Name", value: "authId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "partecipatedProgressCheckCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "partecipatedSpeakingClassCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lessonFeedbacks" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "noShow" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participation" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "targetLanguage" },
                },
                { kind: "Field", name: { kind: "Name", value: "noShow" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomEndDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomStartDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teacher" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "givenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "familyName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "progressCheckResults" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teacher" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "givenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "familyName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "comprehension" },
                },
                { kind: "Field", name: { kind: "Name", value: "accuracy" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "vocabularyRange" },
                },
                { kind: "Field", name: { kind: "Name", value: "fluency" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recommendation" },
                },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheckLevel" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheckStartedAtUtc" },
                },
                { kind: "Field", name: { kind: "Name", value: "passed" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideBookProgressCheckSection" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideBookSpeakingClassSection" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LevelChangeSuggestionFragment, unknown>;
export const SpeakingClassServiceAgGridQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "speakingClassServiceAgGridQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "params" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AgGridParamsInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "speakingClassServiceAgGridQuery" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "params" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "params" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "hasNext" } },
                { kind: "Field", name: { kind: "Name", value: "data" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SpeakingClassServiceAgGridQueryQuery,
  SpeakingClassServiceAgGridQueryQueryVariables
>;
export const GetMinUsedTopicInLevelAmongParticipantsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetMinUsedTopicInLevelAmongParticipants" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "participantIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "speakingLevel" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SpeakingClassLevel" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "getMinUsedTopicsInLevelAmongParticipants",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "participantIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "participantIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "speakingLevel" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "speakingLevel" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "TopicItem" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TopicItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Topic" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "level" } },
          { kind: "Field", name: { kind: "Name", value: "pdfUrl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMinUsedTopicInLevelAmongParticipantsQuery,
  GetMinUsedTopicInLevelAmongParticipantsQueryVariables
>;
export const CreateClassRoomDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createClassRoom" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "capacity" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "classRoomType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ClassRoomType" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "participantIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "speakingLevel" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SpeakingClassLevel" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startUtc" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "teacherId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "topicId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createClassRoom" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "capacity" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "capacity" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "classRoomType" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "classRoomType" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "participantIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "participantIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "speakingLevel" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "speakingLevel" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "startUtc" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "startUtc" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "teacherId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "teacherId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "topicId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "topicId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateClassRoomMutation,
  CreateClassRoomMutationVariables
>;
export const AddParticipantsToClassByEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "addParticipantsToClassByEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "classRoomId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "emails" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addParticipantsToClassByEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "classRoomId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "classRoomId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userEmails" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "emails" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ClassRoomWithParticipants" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomWithParticipants" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ClassRoom" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endedAtUtc" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "topic" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participants" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "givenName" } },
                { kind: "Field", name: { kind: "Name", value: "familyName" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "capacity" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddParticipantsToClassByEmailMutation,
  AddParticipantsToClassByEmailMutationVariables
>;
export const AdminGetClassRoomByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "adminGetClassRoomById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adminGetClassRoomById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "classRoomId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CompleteClassRoom" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompleteClassRoom" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CompleteClassRoom" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endedAtUtc" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "topic" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participants" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "givenName" } },
                { kind: "Field", name: { kind: "Name", value: "familyName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "capacity" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "teacher" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "familyName" } },
                { kind: "Field", name: { kind: "Name", value: "givenName" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "conferenceUri" } },
          { kind: "Field", name: { kind: "Name", value: "feedbackUrl" } },
          { kind: "Field", name: { kind: "Name", value: "feedbackReportUrl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminGetClassRoomByIdQuery,
  AdminGetClassRoomByIdQueryVariables
>;
export const ListClassRoomsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "listClassRooms" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "nextToken" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "prevToken" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateRange" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ListClassRoomDateInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listClassRooms" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "limit" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "nextToken" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "nextToken" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "prevToken" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "prevToken" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dateRange" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateRange" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRooms" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ClassRoomListItem" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                { kind: "Field", name: { kind: "Name", value: "hasPrevPage" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nextPageToken" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "prevPageToken" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomListItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ClassRoomListItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "participantsCount" } },
          { kind: "Field", name: { kind: "Name", value: "capacity" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "topic" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "teacher" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "givenName" } },
                { kind: "Field", name: { kind: "Name", value: "familyName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "phone" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "number" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "completedFeedbacksCount" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListClassRoomsQuery, ListClassRoomsQueryVariables>;
export const DeleteClassRoomDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteClassRoom" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "classRoomId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteClassRoom" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "classRoomId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "classRoomId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteClassRoomMutation,
  DeleteClassRoomMutationVariables
>;
export const RemoveParticipantFromClassRoomDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "removeParticipantFromClassRoom" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "classRoomId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removeParticipantFromClassRoom" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "classRoomId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "classRoomId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomDeleted" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoom" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ClassRoomWithParticipants",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomWithParticipants" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ClassRoom" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endedAtUtc" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "topic" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participants" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "givenName" } },
                { kind: "Field", name: { kind: "Name", value: "familyName" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "capacity" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveParticipantFromClassRoomMutation,
  RemoveParticipantFromClassRoomMutationVariables
>;
export const SwapTeacherDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "swapTeacher" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "classRoomId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "teacherId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "doNotMakeOldTeacherAvailableAgain" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "swapTeacher" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "classRoomId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "classRoomId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "teacherToAddId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "teacherId" },
                },
              },
              {
                kind: "Argument",
                name: {
                  kind: "Name",
                  value: "doNotMakeOldTeacherAvailableAgain",
                },
                value: {
                  kind: "Variable",
                  name: {
                    kind: "Name",
                    value: "doNotMakeOldTeacherAvailableAgain",
                  },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwapTeacherMutation, SwapTeacherMutationVariables>;
export const RemoveAvailabilitySlotDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "removeAvailabilitySlot" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "slotId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removeAvailabilitySlot" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slotId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slotId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveAvailabilitySlotMutation,
  RemoveAvailabilitySlotMutationVariables
>;
export const GetAvailabilityByTeacherForWeekDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getAvailabilityByTeacherForWeek" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fromDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "toDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "teacherId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "timezone" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAvailabilityByTeacherForWeek" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fromDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fromDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "toDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "toDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "teacherId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "teacherId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "timezone" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "timezone" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AvailabilitySlotFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AvailabilitySlotFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AvailabilitySlotDto" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "slotId" } },
          { kind: "Field", name: { kind: "Name", value: "startDateUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endDateUtc" } },
          { kind: "Field", name: { kind: "Name", value: "slotTimezone" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAvailabilityByTeacherForWeekQuery,
  GetAvailabilityByTeacherForWeekQueryVariables
>;
export const GetTeacherClassRoomsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getTeacherClassRooms" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "timezone" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "teacherId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fromDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "toDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getTeacherClassRooms" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "timezone" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "timezone" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "teacherId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "teacherId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "fromDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fromDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "toDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "toDate" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ClassRoomWithoutParticipant" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomWithoutParticipant" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ClassRoomWithoutParticipantDto" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endedAtUtc" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "topic" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "participantsCount" } },
          { kind: "Field", name: { kind: "Name", value: "classRoomTimezone" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTeacherClassRoomsQuery,
  GetTeacherClassRoomsQueryVariables
>;
export const GetAllFranchiseesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getAllFranchisees" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAllFranchisees" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FranchiseeItem" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FranchiseeItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Franchisee" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "phoneNumber" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "number" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllFranchiseesQuery,
  GetAllFranchiseesQueryVariables
>;
export const GetAvailableTeachersInHourDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getAvailableTeachersInHour" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startUtc" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "timezone" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAvailableTeachersInHour" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "startUtc" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "startUtc" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "timezone" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "timezone" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ClassRoomTeacherFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomTeacherFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ClassRoomTeacher" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "phone" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "number" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAvailableTeachersInHourQuery,
  GetAvailableTeachersInHourQueryVariables
>;
export const CreateFranchiseeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createFranchisee" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "familyName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "givenName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phoneNumber" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PhoneNumberInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createFranchisee" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "familyName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "familyName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "givenName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "givenName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "phoneNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phoneNumber" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FranchiseeItem" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FranchiseeItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Franchisee" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "phoneNumber" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "number" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFranchiseeMutation,
  CreateFranchiseeMutationVariables
>;
export const DeleteTeacherDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteTeacher" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "teacherId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteTeacher" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "teacherId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "teacherId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTeacherMutation,
  DeleteTeacherMutationVariables
>;
export const GetFranchiseeByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getFranchiseeById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getFranchiseeById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FranchiseeItem" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FranchiseeItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Franchisee" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "phoneNumber" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "number" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFranchiseeByIdQuery,
  GetFranchiseeByIdQueryVariables
>;
export const GetAllTopicsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getAllTopics" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAllTopics" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "TopicItem" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TopicItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Topic" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "level" } },
          { kind: "Field", name: { kind: "Name", value: "pdfUrl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllTopicsQuery, GetAllTopicsQueryVariables>;
export const CreateTopicDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createTopic" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "nameIt" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "level" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SpeakingClassLevel" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pdfUrl" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createTopic" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "nameIt" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "nameIt" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "level" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "level" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pdfUrl" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pdfUrl" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "level" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTopicMutation, CreateTopicMutationVariables>;
export const EditTopicDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "editTopic" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "nameIt" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pdfUrl" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "editTopic" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "nameIt" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "nameIt" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pdfUrl" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pdfUrl" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "TopicItem" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TopicItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Topic" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "level" } },
          { kind: "Field", name: { kind: "Name", value: "pdfUrl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditTopicMutation, EditTopicMutationVariables>;
export const LogicallyDeleteTopicDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "logicallyDeleteTopic" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "topicId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "logicallyDeleteTopic" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "topicId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LogicallyDeleteTopicMutation,
  LogicallyDeleteTopicMutationVariables
>;
export const GetTopicByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getTopicById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getTopicById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "TopicItem" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TopicItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Topic" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "level" } },
          { kind: "Field", name: { kind: "Name", value: "pdfUrl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTopicByIdQuery, GetTopicByIdQueryVariables>;
export const AddFrozenWeekToUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "addFrozenWeekToUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "week" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "FrozenWeekInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addFrozenWeekToUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "week" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "week" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddFrozenWeekToUserMutation,
  AddFrozenWeekToUserMutationVariables
>;
export const RemoveFrozenWeekForUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "removeFrozenWeekForUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "week" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "FrozenWeekInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removeFrozenWeekForUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "week" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "week" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveFrozenWeekForUserMutation,
  RemoveFrozenWeekForUserMutationVariables
>;
export const GetFreezableWeeksByUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getFreezableWeeksByUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getFreezableWeeksByUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "GetFreezableWeeksResponse" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GetFreezableWeeksResponse" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "GetFreezableWeeksResponse" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "weeks" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "planId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "week" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startOfWeekUtc" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endOfWeekUtc" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "remainingWeeksToFreeze" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "remainingCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "planId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFreezableWeeksByUserQuery,
  GetFreezableWeeksByUserQueryVariables
>;
export const GetFrozenWeeksByUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getFrozenWeeksByUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getFrozenWeeksByUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FrozenWeek" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FrozenWeek" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FrozenWeekDto" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "startOfWeekUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endOfWeekUtc" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFrozenWeeksByUserQuery,
  GetFrozenWeeksByUserQueryVariables
>;
export const CalculatePlanGivenSlotByParamsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "calculatePlanGivenSlotByParams" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endedAtWeekUtc" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lessonsPerWeek" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startedAtWeekUtc" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "calculatePlanGivenSlotByParams" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "endedAtWeekUtc" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "endedAtWeekUtc" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "lessonsPerWeek" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "lessonsPerWeek" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "startedAtWeekUtc" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "startedAtWeekUtc" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CalculatePlanGivenSlotByParamsQuery,
  CalculatePlanGivenSlotByParamsQueryVariables
>;
export const CreateClassRoomPlanDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createClassRoomPlan" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startedAtWeekUtc" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lessonsPerWeek" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endedAtWeekUtc" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "planToRecoverId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "warrantyConfig" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "UserClassRoomPlanWarrantyConfigInputDto",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createClassRoomPlan" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "endedAtWeekUtc" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "endedAtWeekUtc" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "lessonsPerWeek" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "lessonsPerWeek" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "startedAtWeekUtc" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "startedAtWeekUtc" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "planToRecoverId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "planToRecoverId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "warrantyConfig" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "warrantyConfig" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ClassRoomPlanFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomPlanFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "UserClassRoomPlan" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtWeekUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endedAtWeekUtc" } },
          { kind: "Field", name: { kind: "Name", value: "lessonsPerWeek" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "numberOfSlotIncluded" },
          },
          { kind: "Field", name: { kind: "Name", value: "planToRecoverId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "warrantyConfig" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "enabled" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "minAttendedLessons" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateClassRoomPlanMutation,
  CreateClassRoomPlanMutationVariables
>;
export const DeleteClassRoomPlanDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteClassRoomPlan" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "planId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteClassRoomPlan" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "planId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "planId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteClassRoomPlanMutation,
  DeleteClassRoomPlanMutationVariables
>;
export const GetClassRoomPlanByIdAndUserIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getClassRoomPlanByIdAndUserId" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "planId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getClassRoomPlanByIdAndUserId" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "planId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "planId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ClassRoomPlanFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomPlanFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "UserClassRoomPlan" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtWeekUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endedAtWeekUtc" } },
          { kind: "Field", name: { kind: "Name", value: "lessonsPerWeek" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "numberOfSlotIncluded" },
          },
          { kind: "Field", name: { kind: "Name", value: "planToRecoverId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "warrantyConfig" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "enabled" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "minAttendedLessons" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClassRoomPlanByIdAndUserIdQuery,
  GetClassRoomPlanByIdAndUserIdQueryVariables
>;
export const SetUserClassRoomPlanAsRecoveryOfDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "setUserClassRoomPlanAsRecoveryOf" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "planToRecoverId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "planId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "warrantyConfig" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "UserClassRoomPlanWarrantyConfigInputDto",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUserClassRoomPlan" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "planToRecoverId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "planToRecoverId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "planId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "planId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "warrantyConfig" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "warrantyConfig" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ClassRoomPlanFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomPlanFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "UserClassRoomPlan" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtWeekUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endedAtWeekUtc" } },
          { kind: "Field", name: { kind: "Name", value: "lessonsPerWeek" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "numberOfSlotIncluded" },
          },
          { kind: "Field", name: { kind: "Name", value: "planToRecoverId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "warrantyConfig" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "enabled" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "minAttendedLessons" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetUserClassRoomPlanAsRecoveryOfMutation,
  SetUserClassRoomPlanAsRecoveryOfMutationVariables
>;
export const GetUserClassRoomPlansDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getUserClassRoomPlans" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getUserClassRoomPlans" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ClassRoomPlanFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClassRoomPlanFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "UserClassRoomPlan" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          { kind: "Field", name: { kind: "Name", value: "startedAtWeekUtc" } },
          { kind: "Field", name: { kind: "Name", value: "endedAtWeekUtc" } },
          { kind: "Field", name: { kind: "Name", value: "lessonsPerWeek" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "numberOfSlotIncluded" },
          },
          { kind: "Field", name: { kind: "Name", value: "planToRecoverId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "warrantyConfig" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "enabled" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "minAttendedLessons" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserClassRoomPlansQuery,
  GetUserClassRoomPlansQueryVariables
>;
export const GetUserRemainingLessonsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getUserRemainingLessons" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getUserRemainingLessons" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserRemainingLessonsQuery,
  GetUserRemainingLessonsQueryVariables
>;
export const DeleteLessonFeedbackDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteLessonFeedback" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lessonFeedbackId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteLessonFeedback" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "lessonFeedbackId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "lessonFeedbackId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteLessonFeedbackMutation,
  DeleteLessonFeedbackMutationVariables
>;
export const GetLessonFeedbackReportByUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getLessonFeedbackReportByUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getLessonFeedbackReportByUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "LessonFeedbackReportFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LessonFeedbackReportFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "UserLessonFeedbackReport" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "avgParticipationScore" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avgTargetLanguageScore" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "notes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomStartDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomTopicName" },
                },
                { kind: "Field", name: { kind: "Name", value: "note" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hasAtLeastOneFeedback" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLessonFeedbackReportByUserQuery,
  GetLessonFeedbackReportByUserQueryVariables
>;
export const ListNotResolvedLevelChangeSuggestionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "listNotResolvedLevelChangeSuggestions" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "listNotResolvedLevelChangeSuggestions",
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "LevelChangeSuggestion" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          { kind: "Field", name: { kind: "Name", value: "authId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "partecipatedProgressCheckCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "partecipatedSpeakingClassCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lessonFeedbacks" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "noShow" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participation" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "targetLanguage" },
                },
                { kind: "Field", name: { kind: "Name", value: "noShow" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomEndDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomStartDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teacher" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "givenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "familyName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "progressCheckResults" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teacher" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "givenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "familyName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "comprehension" },
                },
                { kind: "Field", name: { kind: "Name", value: "accuracy" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "vocabularyRange" },
                },
                { kind: "Field", name: { kind: "Name", value: "fluency" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recommendation" },
                },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheckLevel" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheckStartedAtUtc" },
                },
                { kind: "Field", name: { kind: "Name", value: "passed" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideBookProgressCheckSection" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideBookSpeakingClassSection" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LevelChangeSuggestion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "LevelChangeSuggestionDto" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "suggestedLevels" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserFragment" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListNotResolvedLevelChangeSuggestionsQuery,
  ListNotResolvedLevelChangeSuggestionsQueryVariables
>;
export const ResolveLevelSuggestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "resolveLevelSuggestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "suggestionId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resolveLevelSuggestion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "suggestionId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "suggestionId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResolveLevelSuggestionMutation,
  ResolveLevelSuggestionMutationVariables
>;
export const GetUsersEligibleForWarrantyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getUsersEligibleForWarranty" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "plansEndBefore" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "plansEndAfter" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getUsersEligibleForWarranty" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "plansEndBefore" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "plansEndBefore" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "plansEndAfter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "plansEndAfter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          { kind: "Field", name: { kind: "Name", value: "authId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "partecipatedProgressCheckCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "partecipatedSpeakingClassCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lessonFeedbacks" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "noShow" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participation" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "targetLanguage" },
                },
                { kind: "Field", name: { kind: "Name", value: "noShow" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomEndDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomStartDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teacher" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "givenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "familyName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "progressCheckResults" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teacher" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "givenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "familyName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "comprehension" },
                },
                { kind: "Field", name: { kind: "Name", value: "accuracy" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "vocabularyRange" },
                },
                { kind: "Field", name: { kind: "Name", value: "fluency" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recommendation" },
                },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheckLevel" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheckStartedAtUtc" },
                },
                { kind: "Field", name: { kind: "Name", value: "passed" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideBookProgressCheckSection" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideBookSpeakingClassSection" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUsersEligibleForWarrantyQuery,
  GetUsersEligibleForWarrantyQueryVariables
>;
export const ListAllUsersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "listAllUsers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "nextPageToken" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "prevPageToken" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withSpeakingLevel" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SpeakingLevel" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listAllUsers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "nextPageToken" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "nextPageToken" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "limit" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "prevPageToken" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "prevPageToken" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "withSpeakingLevel" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "withSpeakingLevel" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "users" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "UserFragment" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nextPageToken" },
                },
                { kind: "Field", name: { kind: "Name", value: "hasPrevPage" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "prevPageToken" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          { kind: "Field", name: { kind: "Name", value: "authId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "partecipatedProgressCheckCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "partecipatedSpeakingClassCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lessonFeedbacks" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "noShow" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participation" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "targetLanguage" },
                },
                { kind: "Field", name: { kind: "Name", value: "noShow" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomEndDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomStartDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teacher" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "givenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "familyName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "progressCheckResults" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teacher" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "givenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "familyName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "comprehension" },
                },
                { kind: "Field", name: { kind: "Name", value: "accuracy" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "vocabularyRange" },
                },
                { kind: "Field", name: { kind: "Name", value: "fluency" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recommendation" },
                },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheckLevel" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheckStartedAtUtc" },
                },
                { kind: "Field", name: { kind: "Name", value: "passed" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideBookProgressCheckSection" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideBookSpeakingClassSection" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListAllUsersQuery, ListAllUsersQueryVariables>;
export const CreateUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "speakingLevel" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SpeakingLevel" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "givenName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "familyName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "activateAccount" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hideBookSpeakingClassSection" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hideBookProgressCheckSection" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "speakingLevel" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "speakingLevel" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "givenName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "givenName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "familyName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "familyName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "activateAccount" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "activateAccount" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideBookSpeakingClassSection" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "hideBookSpeakingClassSection" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideBookProgressCheckSection" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "hideBookProgressCheckSection" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          { kind: "Field", name: { kind: "Name", value: "authId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "partecipatedProgressCheckCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "partecipatedSpeakingClassCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lessonFeedbacks" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "noShow" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participation" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "targetLanguage" },
                },
                { kind: "Field", name: { kind: "Name", value: "noShow" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomEndDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomStartDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teacher" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "givenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "familyName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "progressCheckResults" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teacher" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "givenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "familyName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "comprehension" },
                },
                { kind: "Field", name: { kind: "Name", value: "accuracy" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "vocabularyRange" },
                },
                { kind: "Field", name: { kind: "Name", value: "fluency" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recommendation" },
                },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheckLevel" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheckStartedAtUtc" },
                },
                { kind: "Field", name: { kind: "Name", value: "passed" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideBookProgressCheckSection" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideBookSpeakingClassSection" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const EnrollUserToAllPaidContentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "enrollUserToAllPaidContent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "enrollUserToAllPaidContent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EnrollUserToAllPaidContentMutation,
  EnrollUserToAllPaidContentMutationVariables
>;
export const IsActiveUserForAdminByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "isActiveUserForAdminById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "isActiveUserForAdminById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  IsActiveUserForAdminByIdQuery,
  IsActiveUserForAdminByIdQueryVariables
>;
export const UpdateUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "familyName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "givenName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "speakingLevel" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "SpeakingLevel" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "activateAccount" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hideBookSpeakingClassSection" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hideBookProgressCheckSection" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "familyName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "familyName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "givenName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "givenName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "speakingLevel" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "speakingLevel" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "activateAccount" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "activateAccount" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideBookSpeakingClassSection" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "hideBookSpeakingClassSection" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideBookProgressCheckSection" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "hideBookProgressCheckSection" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          { kind: "Field", name: { kind: "Name", value: "authId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "partecipatedProgressCheckCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "partecipatedSpeakingClassCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lessonFeedbacks" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "noShow" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participation" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "targetLanguage" },
                },
                { kind: "Field", name: { kind: "Name", value: "noShow" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomEndDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomStartDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teacher" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "givenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "familyName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "progressCheckResults" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teacher" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "givenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "familyName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "comprehension" },
                },
                { kind: "Field", name: { kind: "Name", value: "accuracy" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "vocabularyRange" },
                },
                { kind: "Field", name: { kind: "Name", value: "fluency" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recommendation" },
                },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheckLevel" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheckStartedAtUtc" },
                },
                { kind: "Field", name: { kind: "Name", value: "passed" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideBookProgressCheckSection" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideBookSpeakingClassSection" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetUserByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getUserById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getUserById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "speakingLevel" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "familyName" } },
          { kind: "Field", name: { kind: "Name", value: "authId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "partecipatedProgressCheckCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "partecipatedSpeakingClassCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lessonFeedbacks" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "noShow" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participation" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "targetLanguage" },
                },
                { kind: "Field", name: { kind: "Name", value: "noShow" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomEndDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "classRoomStartDateUtc" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teacher" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "givenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "familyName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "progressCheckResults" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teacher" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "givenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "familyName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "comprehension" },
                },
                { kind: "Field", name: { kind: "Name", value: "accuracy" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "vocabularyRange" },
                },
                { kind: "Field", name: { kind: "Name", value: "fluency" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recommendation" },
                },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheckLevel" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "progressCheckStartedAtUtc" },
                },
                { kind: "Field", name: { kind: "Name", value: "passed" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideBookProgressCheckSection" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideBookSpeakingClassSection" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserByIdQuery, GetUserByIdQueryVariables>;
