import { useDeleteTeacher } from "./useDeleteTeacher.ts";
import { DropDown } from "../UI/DropDown/DropDown.tsx";
import {
  CalendarIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import { DropDownItem } from "../UI/DropDown/DropDownItem.tsx";
import { useToTeacherAvailability } from "./TeacherAvailability/useToTeacherAvailability.ts";
import { TrashIcon } from "@heroicons/react/24/outline/index.js";
import { PrimaryLoadingIndicator } from "../UI/Loading/PrimaryLoadingIndicator.tsx";
import { LoaderSize } from "../UI/Loading/LoaderSize.tsx";
import { ErrorMessagePopup } from "../UI/MessagePopup/ErrorMessagePopup.tsx";
import { useMessagePopupController } from "../UI/MessagePopup/useMessagePopupController.ts";
import { ApolloError } from "@apollo/client";

export function TeacherTableDotDropdown({ teacherId }: { teacherId: string }) {
  const { toTeacherAvailability } = useToTeacherAvailability();
  const { loading: deleting, deleteTeacher } = useDeleteTeacher();
  const { show, showPopup, description, title } = useMessagePopupController(
    4 * 1000,
  );

  async function confirmDeleteTeacher() {
    await deleteTeacher(teacherId);
  }

  async function requestDeleteTeacher() {
    if (
      window.confirm(
        "Are you sure you want to delete this teacher? This action cannot be undone",
      )
    ) {
      try {
        await confirmDeleteTeacher();
      } catch (e) {
        const error = e as ApolloError;
        showPopup("An error occurred", error?.message ?? "Unknown error");
      }
    }
  }
  return (
    <>
      <ErrorMessagePopup title={title} description={description} show={show} />
      <div className={"flex justify-end"}>
        <div>
          <DropDown
            activator={
              <EllipsisHorizontalIcon
                className={"text-slate-800 w-5 h-5 group-hover:text-white"}
              />
            }
          >
            <DropDownItem onClick={() => toTeacherAvailability(teacherId)}>
              <CalendarIcon className={"w-5 h-5 mr-2 flex-shrink-0"} />
              Availability
            </DropDownItem>
            <DropDownItem onClick={requestDeleteTeacher}>
              {deleting ? (
                <span className={"mr-2"}>
                  <PrimaryLoadingIndicator size={LoaderSize.Small} />
                </span>
              ) : (
                <TrashIcon className={"w-5 h-5 mr-2 flex-shrink-0"} />
              )}
              Delete
            </DropDownItem>
          </DropDown>
        </div>
      </div>
    </>
  );
}
