import { FranchiseeItemFragment } from "../gql/graphql.ts";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { BasicTable } from "../UI/Table/BasicTable.tsx";
import { TeacherTableDotDropdown } from "./TeacherTableDotDropdown.tsx";

export function FranchiseeTable(props: {
  franchisees: FranchiseeItemFragment[];
  editFranchisee: (franchisee: FranchiseeItemFragment) => void;
}) {
  const franchisees = props.franchisees;
  const columnHelper = createColumnHelper<FranchiseeItemFragment>();

  const columns = [
    columnHelper.accessor("givenName", {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
      header: "Name",
    }),
    columnHelper.accessor("familyName", {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
      header: "Surname",
    }),
    columnHelper.accessor("email", {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
      header: "Email",
    }),
    columnHelper.accessor("phoneNumber", {
      cell: (info) => {
        const v = info.getValue();
        return v ? `${v.countryCode} ${v.number}` : "";
      },
      footer: (info) => info.column.id,
      header: "Phone number",
    }),
    columnHelper.display({
      id: "actions",
      cell: (v) => <TeacherTableDotDropdown teacherId={v.row.original.id} />,
    }),
  ];
  const table = useReactTable({
    columns,
    data: franchisees as Array<FranchiseeItemFragment>,
    getCoreRowModel: getCoreRowModel(),
  });
  return <BasicTable table={table} />;
}
