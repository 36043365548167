import { graphql } from "../gql";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { GetAllFranchiseesQuery } from "./useAllFranchisees.ts";

const DeleteTeacherMutation = graphql(`
  mutation deleteTeacher($teacherId: String!) {
    deleteTeacher(teacherId: $teacherId)
  }
`);
export function useDeleteTeacher() {
  const [deleteTeacherMutation, { loading }] = useMutation(
    DeleteTeacherMutation,
    {
      refetchQueries: [GetAllFranchiseesQuery],
    },
  );

  const deleteTeacher = useCallback(
    async (teacherId: string) => {
      await deleteTeacherMutation({
        variables: {
          teacherId,
        },
      });
    },
    [deleteTeacherMutation],
  );
  return {
    deleteTeacher,
    loading,
  };
}
